import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import { useApi } from '../../../hooks/useApi'
import { Breadcrumbs,  Button, Chip, Container, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import MuiLink from '@mui/material/Link'

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';

function Index() {
    const theme = useTheme();

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const [teamslayUser,setTeamslayUser] = useState(null)
    const [rows,setRows] = useState({ data :[], has_more_next : false, has_more_prev : false});
    const PAGE_SIZE = 25;
    
    const defaultPagination = {
        starting_after: null,
        ending_before: null,
        pageSize: PAGE_SIZE,
        page: 0,
    };
    const [paginationModel, setPaginationModel] = useState(defaultPagination);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const fetchData = async (pm) => {    
        try {

            setErrorMessage("")
            setIsLoading(true)

            //Get the requesting user based on API Token
            const responsets = await jApi.request("teamslay/user/get", {} )
            const teamslay_user = responsets.data.result
            setTeamslayUser(teamslay_user)

            const responseInvoices = await jApi.request("stripe/invoice/list", { customer_id : teamslay_user.stripe_customerid, starting_after : pm?.starting_after, ending_before: pm?.ending_before, limit : pm?.pageSize} )

            
            let has_more_next = false;
            let has_more_prev = false;
            
            if(responseInvoices.data.result.data) {
   
                invoices = responseInvoices.data.result.data
                if((pm?.starting_after === null &&  pm?.ending_before === null)  ) { 
                    has_more_next = responseInvoices.data.result.has_more;
                }

                if(pm?.starting_after !== null){
                    has_more_next = responseInvoices.data.result.has_more;
                    has_more_prev = true;
                }

                
                if(pm?.ending_before !== null ) { 
                    has_more_next = true;
                    has_more_prev = responseInvoices.data.result.has_more
                }

            }

            var invoices = []; 
            if(responseInvoices.data.result.data != null) {
              invoices = responseInvoices.data.result.data
            }

            setRows({ data: invoices, has_more_next : has_more_next, has_more_prev : has_more_prev }); 

        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true);
        fetchData(paginationModel);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };


    }, []);

    const handlePageNext = async (starting_after) => {
        const newPaginationModel = {
            starting_after: starting_after,
            ending_before: null,
            pageSize: PAGE_SIZE,
          }
        setPaginationModel(newPaginationModel);
        await fetchData(newPaginationModel)
      };

      const handlePagePrevious = async (ending_before) => {
        const newPaginationModel = {
            starting_after: null,
            ending_before: ending_before,
            pageSize: PAGE_SIZE,
        }
        setPaginationModel(newPaginationModel);
        await fetchData(newPaginationModel)
      };

    function CustomFooterStatusComponent(props) {
        return (
            <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'end',}} >
 
                {rows.has_more_prev ? 
                    <Button  variant="text"  onClick={() => handlePagePrevious(rows.data[0].id) }>previous</Button>
                    :
                    <Button  variant="text" disabled  >previous</Button>
                }
                {rows.has_more_next ? 
                    <Button  variant="text"  onClick={() => handlePageNext(rows.data[rows.data.length-1].id) }  >next</Button>
                    :
                    <Button  variant="text" disabled  >next</Button>
                }
                
            </Stack>
        );


      }

      const InvoiceChip = ({ status, amount, amount_refunded }) => {

        let chip = <Chip  color='default' label={status} size="small" />
        if (status === 'paid') {
          chip = <Chip  color='success' sx={{backgroundColor: (theme) => theme.palette.success.light }} label={status} size="small" />
        } else if (status === 'draft' || status === 'void' || status === 'uncollectible' ) {
          chip = <Chip  color='info' label={status} size="small" />
        }  else  {
          chip = <Chip  color='error' label={status} size="small" />
        }

       
        if(amount_refunded === amount){
          chip = <Tooltip title="Fully Refunded"><Chip size="small" icon={<TurnLeftIcon />} color='success' sx={{backgroundColor: (theme) => theme.palette.success.light }} label={status}  /></Tooltip>
        } else if (amount_refunded !== amount && amount_refunded > 0) {
          const tooltipString = "Partial Refund.   " + (amount_refunded /100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
          chip = <Tooltip title={tooltipString}><Chip icon={<TurnLeftIcon />} color='success'  sx={{backgroundColor: (theme) => theme.palette.success.light }} label={status} size="small" /></Tooltip>

        }
      
        return chip;
      };    
    

    return(
        <Container  maxWidth="md" component="main" >
        
        <Grid container spacing={0}  sx={{ pt: 4, alignContent: 'left', textAlign: 'left', }} >
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                    <Typography  sx={{  paddingTop: '2px'}} color="text.primary">{teamslayUser?.username}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}  sx={{pt: 1, pb: 2}}>
                <Typography  variant="h5"> Invoices</Typography>
            </Grid>

            <Grid container  spacing={0}  sx={{ pt: 0, alignContent: 'left', textAlign: 'left', }} >
                <DataGrid
                  rows={rows.data}
                  columnVisibilityModel={{
                    customer_name: windowWidth > 600 ? true : false,
                  }}
                  columns={[
                      { field: 'number', headerName: 'Number', flex: .5, minWidth: 125,   sortable : false, filterable : false,
                      renderCell: (params) => (
                        <MuiLink href={params.row.hosted_invoice_url} target='_blank' sx={{color:  (theme) => theme.palette.primary.main, textDecoration : 'none'}}  >
                        {params.value}
                      </MuiLink>
                      )
                     },
                      { field: 'amount_due', headerName: 'Due', flex: .5 , minWidth: 150,  sortable : false, filterable : false,

                      renderCell: (params) => (
                        <MuiLink href={params.row.charge.receipt_url} target='_blank' color="primary" style={{ textDecoration: 'none' }}>
                          <Stack  direction="row" spacing={1}>
                              <Typography variant='body2'>
                              {(params.value /100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
                              </Typography>
                            <InvoiceChip status={params.row.status} amount={params.row.charge.amount} amount_refunded={params.row.charge.amount_refunded}></InvoiceChip>
                          </Stack>
                        </MuiLink>
                        ),
                      },
                      { field: 'customer_name', headerName: 'Name', flex: .5 , sortable : false, filterable : true, },
                      { field: 'created', headerName: 'Created',  minWidth: 150,flex: 0.3,  sortable : false, filterable : false,
                      valueFormatter: (params) => {
                          const formattedDate = new Date(params.value* 1000).toLocaleDateString('en-US');
                          return formattedDate;
                        }, 
                      }
                      
                  ]}
                  showPagination={false}
                  pageSize={PAGE_SIZE}
                  rowCount={1000000} // Not using this but it is required
                  loading={isLoading}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  hideFooterRowCount={true}
                  slots={{
                      footer: CustomFooterStatusComponent,
                    }}
                />
                </Grid>
            
                <Grid item>
                    <Typography variant='body'>{errorMessage}</Typography>
                </Grid>
        </Grid>


        
        </Container>
    

    )

}

export default Index