
import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import HomeIcon from '@mui/icons-material/Home';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Breadcrumbs, Button, Box, Chip, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { useApi } from '../../../hooks/useApi'
import { DataGrid, getGridStringOperators, GridRowId, GridPaginationModel} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox'
import LinearProgress from '@mui/material/LinearProgress';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import TextField from '@mui/material/TextField';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Select from '@mui/material/Select';
import MenuItem  from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputAdornment from '@mui/material/InputAdornment'


import DeleteIcon from '@mui/icons-material/Delete';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';


function Coupons() {

    const jApi = useApi();

    const PAGE_SIZE = 10;

    const [rows,setRows] = useState({ data :[], has_more_next : false, has_more_prev : false});
    const [isLoading, setIsLoading] = useState(true);


    const defaultPagination = {
        starting_after: null,
        ending_before: null,
        pageSize: PAGE_SIZE,
        page: 0,
    };
    const [paginationModel, setPaginationModel] = useState(defaultPagination);

    const [errorMessage, setErrorMessage] = useState();

    const fetchData = async (pm) => {    
        try {

            setErrorMessage("")
            setIsLoading(true);
            const responseUsers = await jApi.request("stripe/coupon/list", { starting_after : pm?.starting_after, ending_before: pm?.ending_before, limit : pm?.pageSize},);

            let has_more_next = false;
            let has_more_prev = false;
            var invoices = []; 
            if(responseUsers.data.result.data) {
   
                invoices = responseUsers.data.result.data
                if((pm?.starting_after === null &&  pm?.ending_before === null)  ) { 
                    has_more_next = responseUsers.data.result.has_more;
                }

                if(pm?.starting_after !== null){
                    has_more_next = responseUsers.data.result.has_more;
                    has_more_prev = true;
                }

                
                if(pm?.ending_before !== null ) { 
                    has_more_next = true;
                    has_more_prev = responseUsers.data.result.has_more
                }

            }

            setRows({ data: invoices, has_more_next : has_more_next, has_more_prev : has_more_prev }); 
            
        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        fetchData(paginationModel);
    }, [])

    const handlePageNext = async (starting_after) => {
        const newPaginationModel = {
            starting_after: starting_after,
            ending_before: null,
            pageSize: PAGE_SIZE,
          }
        setPaginationModel(newPaginationModel);
        await fetchData(newPaginationModel)
      };

      const handlePagePrevious = async (ending_before) => {
        const newPaginationModel = {
            starting_after: null,
            ending_before: ending_before,
            pageSize: PAGE_SIZE,
        }
        setPaginationModel(newPaginationModel);
        await fetchData(newPaginationModel)
      };


      function CustomFooterStatusComponent(props) {
        return (
            <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'end',}} >
 
                {rows.has_more_prev ? 
                    <Button  variant="text"  onClick={() => handlePagePrevious(rows.data[0].id) }>previous</Button>
                    :
                    <Button  variant="text" disabled  >previous</Button>
                }
                {rows.has_more_next ? 
                    <Button  variant="text"  onClick={() => handlePageNext(rows.data[rows.data.length-1].id) }  >next</Button>
                    :
                    <Button  variant="text" disabled  >next</Button>
                }
                
            </Stack>
        );


      }


    // #region New User

    const [dialogNew, setDialogNew] = useState({
        open: false,
        loading: false,
        posting: false,
        errorMessage: '',
        name : '',
        name_error: false,
        type_off : 'amount_off',
        percent_off : 0,
        percent_off_error : false,
        amount_off: 0,
        amount_off_error: false,
        duration : 'forever',
        duration_in_months : 3,
        duration_in_months_error : false,
        limit_max_redemptions : false,
        max_redemptions : 0,
        max_redemptions_error : false,
        limit_redeem_by : false,
        redeem_by : null,
        redeem_by_error : false
    });
    
    const dialogNew_onOpen = async () => {
        setDialogNew((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: '',      
          name : '',
          name_error: false,
          type_off : 'amount_off',
          percent_off : 0,
          percent_off_error : false,
          amount_off: 0,
          amount_off_error: false,
          duration : 'forever',
          duration_in_months : 3,
          duration_in_months_error : false,
          limit_max_redemptions : false,
          max_redemptions : 0,      
          max_redemptions_error : false,
          limit_redeem_by : false,
          redeem_by : null,
          redeem_by_error : false
        }));
    };
  
    const dialogNew_onClose =  () => {
        setDialogNew((prevState) => ({...prevState, open: false,       
          name : '',
          name_error: false,
          type_off : 'amount_off',
          percent_off : 0,
          percent_off_error : false,
          amount_off: 0,
          amount_off_error: false,
          duration : 'forever',
          duration_in_months : 3,
          duration_in_months_error : false,
          limit_max_redemptions : false,
          max_redemptions : 0,          
          max_redemptions_error : false,
          limit_redeem_by : false,
          redeem_by : null,
          redeem_by_error : false
        }));
    };
  
    const dialogNew_ValidateForm = () => {


        const { name, type_off, percent_off, amount_off,  duration, duration_in_months, limit_max_redemptions, max_redemptions, limit_redeem_by, redeem_by } = dialogNew

        let errors = {
            name_error : false,
            amount_off_error : false,
            percent_off_error : false,
            duration_in_months_error : false,
            max_redemptions_error : false,
            redeem_by_error : false
        }

        if(name === "") { errors.name_error = true }
        if (type_off === "amount_off") {
            const numericAmountOff = parseFloat(amount_off);
            if (isNaN(numericAmountOff) || numericAmountOff <= 0) { errors.amount_off_error = true }
        }
        if (type_off === "percent_off") {
            const numericAmountOff = parseFloat(percent_off);
            if (isNaN(numericAmountOff) || numericAmountOff <= 0 || numericAmountOff > 100 || !Number.isInteger(numericAmountOff)) {
                errors.percent_off_error = true;
            }
        }
        if (duration === "repeating") {
            const numericAmountOff = parseFloat(duration_in_months);
            if (isNaN(numericAmountOff) || numericAmountOff <= 0 ||!Number.isInteger(numericAmountOff)) {
                errors.duration_in_months_error = true;
            }
        }
  
        if (limit_max_redemptions) {
            const numericAmountOff = parseFloat(max_redemptions);
            if (isNaN(numericAmountOff) || numericAmountOff <= 0 ||!Number.isInteger(numericAmountOff)) {
                errors.max_redemptions_error = true;
            }
        }
        if(limit_redeem_by) {
            if(redeem_by === null) {
                errors.redeem_by_error = true;
            }
        }

        setDialogNew((prevState) => ({
            ...prevState,
            name_error : errors.name_error,
            amount_off_error : errors.amount_off_error,
            percent_off_error : errors.percent_off_error,
            duration_in_months_error : errors.duration_in_months_error,
            max_redemptions_error : errors.max_redemptions_error,
            redeem_by_error : errors.redeem_by_error
        }))

        let inError = errors.name_error | errors.amount_off_error | errors.percent_off_error | errors.duration_in_months_error | errors.max_redemptions_error | errors.redeem_by_error;
  
      return inError;
    };
  
    const dialogNew_onSubmit = async () => {
        const { posting, name, type_off, percent_off, amount_off,  duration, duration_in_months, limit_max_redemptions, max_redemptions, limit_redeem_by, redeem_by   } = dialogNew
        if(posting) {
            return
        }
    
        try {
            setDialogNew((prevState) => ({...prevState, posting: true }));
  
            const inError = dialogNew_ValidateForm();
  
            if(!inError) {

                let body = { name : name };
                if(type_off === "percent_off") { body.percent_off = percent_off }
                if(type_off === "amount_off") { body.amount_off = amount_off * 100}
                body.duration = duration;                
                if(duration === "repeating") { body.duration_in_months = duration_in_months }

                if(limit_max_redemptions) { body.max_redemptions = max_redemptions}
                if(limit_redeem_by) { 
                    const unixTimestamp = Math.floor(redeem_by.$d.getTime() / 1000);
                    body.redeem_by = unixTimestamp
                }
                const responseUser = await jApi.request("stripe/coupon/post", body);
                await fetchData(paginationModel)
                setDialogNew((prevState) => ({...prevState, open: false }));
            }
  
        } catch (error) {
            setDialogNew((prevState) => ({...prevState, errorMessage: error }));
        } finally {
            setDialogNew((prevState) => ({...prevState, posting: false }));
        }
    };
  
    const dialogNew_onInputChange = (e) => {
      const { name, value } = e.target;
      setDialogNew((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const dialogNew_onRadioChangeTypeOff = (event) => {
        const type_off = event.target.value;
        setDialogNew((prevState) => ({...prevState, type_off: type_off}));
    }
  
    const dialogNew_onDurationChange = (event) => {
        const duration = event.target.value;
        setDialogNew((prevState) => ({...prevState, duration: duration}));
    }

    const dialogNew_onLimitMaxRedeptionChange = (event) => {
        const x = event.target.checked;
        setDialogNew((prevState) => ({...prevState, limit_max_redemptions: x}));
    }

    const dialogNew_onLimitDurationChange = (event) => {
        const x = event.target.checked;
        setDialogNew((prevState) => ({...prevState, limit_redeem_by: x}));
    }

    const dialogNew_onRedeemByChange = (event) => {
       setDialogNew((prevState) => ({...prevState,redeem_by: event}));
    }

    // #endregion
  
 
    // #region Cancel Dialog
  
    const [dialogDelete, setDialogDelete] = useState({
        open: false,
        loading: false,
        posting: false,
        errorMessage: '',
        name : '',
        coupon_id : ''
      });
  
      const dialogDelete_onOpen = async (name, coupon_id) => {
        setDialogDelete((prevState) => ({ ...prevState, open: true, errorMessage: '',name : name, coupon_id : coupon_id}));
      };
  
      const dialogDelete_onClose =  () => {
        setDialogDelete((prevState) => ({...prevState, open: false, name : '', coupon_id : ''}));
      };
  
      const dialogDelete_onSubmit = async () => {
        const { posting, coupon_id } = dialogDelete
        if(posting) {
          return
        }
  
        try {
          setDialogDelete((prevState) => ({...prevState, posting: true }));
          const response = await jApi.request("stripe/coupon/delete", { "coupon_id" : coupon_id });
          setDialogDelete((prevState) => ({...prevState, open: false }));
          await fetchData(paginationModel);
        } catch (error) {
          setDialogDelete((prevState) => ({...prevState, errorMessage: error }));
        } finally {
          setDialogDelete((prevState) => ({...prevState, posting: false }));
        }
      };
    // #endregion


return(
    <Container  maxWidth="md" component="main" >
        
        <Grid container spacing={0}  sx={{ pt: 4, alignContent: 'left', textAlign: 'left', }} >
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                    <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Coupons</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sx={{pt: 1, }}>
                <Typography  variant="h5"> Coupons</Typography>
            </Grid>
            <Grid item xs={12} sx={{   paddingTop: '0px' }} >
                <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'flex-end',}} >
                     <Button  variant="text" onClick={() => dialogNew_onOpen()} >+ ADD COUPON</Button>
                </Stack>
            </Grid>
        </Grid>

        <DataGrid
            rows={rows.data}
            columns={[
   
                { field: 'name', headerName: 'Name',  width: 165,  sortable : false, filterable : false, 
                renderCell: (params) => (<>
                 { params.row.valid? 
                      <Link to={`/admin/coupons/${params.row.id}`} style={{ textDecoration: 'none' }}>
                      {params.value}
                  </Link> :
                 <> {params.value} </> }
                </>
           
                ),
            
                },
                { field: 'duration', headerName: 'Terms',  width: 165,  sortable : false, filterable : false,
                renderCell: (params) => (
                    <>
                        { params.row.amount_off ? <>${params.row.amount_off /100} </> : <>{params.row.percent_off}% </>}
                        off  {params.row.duration === 'forever' || params.row.duration === 'once' ? <> {params.row.duration}</>:
                        <> for {params.row.duration_in_months} months
                        </> }
                    </>
                )},
                { field: 'valid', headerName: 'Status',  width: 100,  sortable : false, filterable : false,
                renderCell: (params) => ( <> { 
                     params.row.valid? 
                        <Chip color="success" label="active" size="small"  variant="contained"/> 
                    :  <Chip color="warning" label="inactive" size="small"  variant="outlined"/> 
                    
                    } </>)},
                { field: 'times_redeemed', headerName: 'Redemptions',  width: 100,  sortable : false, filterable : true,
                renderCell: (params) => (
                    <>
                        { params.row.max_redemptions ? <>{params.row.times_redeemed}/{params.row.max_redemptions}</> : <>{params.row.times_redeemed}</>}
                    </>
                )},
                { field: 'redeem_by', headerName: 'Expires',  width: 165,  sortable : false, filterable : false, 
                valueFormatter: (params) => {
                    if(params.value) {
                    const formattedDate = new Date(params.value* 1000).toLocaleDateString('en-US');
                    return formattedDate;
                    }
                    return null;
                }},
                { field: 'object', headerName: 'Actions',  width: 65,  sortable : false, filterable : true,
                renderCell: (params) => (
                    <Tooltip title="Delete Coupon">
                        <IconButton size="small" aria-label="delete"   onClick={() => dialogDelete_onOpen(params.row.name,params.row.id)}   sx={{color: (theme) => theme.palette.primary.main }}>
                            <DeleteIcon  fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                )},      
                
            ]}
            showPagination={false}
            pageSize={PAGE_SIZE}
            rowCount={1000000} // Not using this but it is required
            loading={isLoading}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            hideFooterRowCount={true}
            slots={{
                footer: CustomFooterStatusComponent,
            }}
        />



        <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>
      
        <Dialog open={dialogNew.open}
          onClose={dialogNew_onClose} 
          aria-labelledby="alert-dialog-title" 
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
              {"Add new coupon"}
          </DialogTitle>
          <DialogContent>

                <Stack>
                <Box component="form" sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                        <TextField
                            size="small"
                            onChange={dialogNew_onInputChange}
                            value={dialogNew.name}
                            error={dialogNew.name_error} // Apply error styling if formErrors.first is true
                            helperText={dialogNew.name_error ? 'Coupon name is required' : ''}
                            autoComplete="name"
                            name="name"
                            required
                            fullWidth
                            id="name"
                            label="Coupon Name"
                            autoFocus
                        />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl>
                                <FormLabel id="subscription-label">Type</FormLabel>
                                <RadioGroup aria-labelledby="radio-buttons-group-label"   row
                                    name="radio-buttons-group"  
                                    value={dialogNew.type_off}
                                    onChange={dialogNew_onRadioChangeTypeOff}>
                                    <FormControlLabel
                                        value='amount_off'
                                        control={<Radio />}
                                        label={ 
                                           <Typography  >
                                            Fixed amount discount
                                            </Typography>
                                        }                                         
                                    ></FormControlLabel>
                                    <FormControlLabel
                                        value='percent_off'
                                        control={<Radio />}
                                        label={ 
                                           <Typography  >
                                            Percentage discount
                                            </Typography>
                                        }                                         
                                    ></FormControlLabel>
                                </RadioGroup>
                             </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl>
                             { dialogNew.type_off === 'amount_off' ? 
                                <TextField
                                size="small"
                                type="number"
                                    onChange={dialogNew_onInputChange}
                                    value={dialogNew.amount_off}
                                    error={dialogNew.amount_off_error} // Apply error styling if formErrors.first is true
                                    helperText={dialogNew.amount_off_error ? 'Invalid amount off' : ''}
                                    autoComplete="amount_off"
                                    name="amount_off"
                                    required
                                    fullWidth
                                    id="amount_off"
                                    label="Discount amount"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                 />
                             : 
                                <TextField
                                size="small"
                                type="number"
                                    onChange={dialogNew_onInputChange}
                                    value={dialogNew.percent_off}
                                    error={dialogNew.percent_off_error} // Apply error styling if formErrors.first is true
                                    helperText={dialogNew.percent_off_error ? 'Invalid percent off' : ''}
                                    autoComplete="percent_off"
                                    name="percent_off"
                                    required
                                    fullWidth
                                    id="percent_off"
                                    label="Percentage off"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                    
                                />                             
                             }

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack  direction="row" spacing={2} >
                                <FormControl>
                                    <FormLabel id="subscription-label">Duration</FormLabel>
                                    <Select
                                        size="small"
                                        labelId="duration-label"
                                        id="duration"
                                        value={dialogNew.duration}
                                        onChange={dialogNew_onDurationChange}
                                    >
                                        <MenuItem value="forever">Forever</MenuItem>
                                        <MenuItem value="once">Once</MenuItem>
                                        <MenuItem value="repeating">Multiple Months</MenuItem>
                                    </Select>
                                </FormControl>
                                {dialogNew.duration === "repeating" ?
                                <FormControl>
                                    <FormLabel id="subscription-label">Number of months</FormLabel>
                                    <TextField
                                        size="small"
                                        type="number"
                                        onChange={dialogNew_onInputChange}
                                        value={dialogNew.duration_in_months}
                                        error={dialogNew.duration_in_months_error} // Apply error styling if formErrors.first is true
                                        helperText={dialogNew.duration_in_months_error ? 'Invalid month duration' : ''}
                                        autoComplete="duration_in_months"
                                        name="duration_in_months"
                                        required
                                        fullWidth
                                        id="duration_in_months"
                                       
                                    />  
                                </FormControl>
                                : null }
                            </Stack>
                        </Grid>
                          
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel control={<Checkbox
                                size="small"
                                checked={dialogNew.limit_max_redemptions}
                                onChange={dialogNew_onLimitMaxRedeptionChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /> } label="Limit the total number of times this coupon can be redeemed."  />

                            {dialogNew.limit_max_redemptions ? 
                             <FormControl>
                                    <TextField
                                    type="number"
                                    size="small"
                                    onChange={dialogNew_onInputChange}
                                    value={dialogNew.max_redemptions}
                                    error={dialogNew.max_redemptions_error} // Apply error styling if formErrors.first is true
                                    helperText={dialogNew.max_redemptions_error ? 'Invalid redemption limit' : ''}
                                    autoComplete="max_redemptions"
                                    name="max_redemptions"
                                    required
                                    fullWidth
                                    id="max_redemptions"
                                    label="Total redemptions"
                                    
                                />  
                                </FormControl>  
                            : null
                            }


                        </Grid>     
                        
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel control={<Checkbox
                                size="small"
                                checked={dialogNew.limit_redeem_by}
                                onChange={dialogNew_onLimitDurationChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /> } label="Limit the date range when customers can redeem this coupon."  />

                            {dialogNew.limit_redeem_by ? 
                             <FormControl>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                    size="small"
                                    onChange={dialogNew_onRedeemByChange}
                                    value={dialogNew.redeem_by}
                                    autoComplete="redeem_by"
                                    name="redeem_by"
                                    required
                                    fullWidth
                                    id="redeem_by"
                                    label="Expiration Date"
                                    
                                />  
                                </LocalizationProvider>
                                {dialogNew.redeem_by_error && (
                                    <Typography variant="caption" color="error">
                                    Please set expiration date.
                                    </Typography>
                                )}
                                </FormControl>  
                            : null
                            }


                        </Grid>                 
                    </Grid>
                </Box>
                <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogNew.errorMessage}</Typography>
                </Stack>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>                    
          </DialogContent>
          <DialogActions>
              {dialogNew.posting ? 
                  <Button variant="contained"  >
                      <CircularProgress size={14} sx={{ color: 'white' }} /> Add Coupon
                  </Button>
              :
                  <Button variant="contained" onClick={dialogNew_onSubmit} autoFocus>
                      Add Coupon
                  </Button>
              }
              <Button variant="outlined" color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogNew_onClose}>Close</Button>
          </DialogActions>
      </Dialog>   



      <Dialog
              open={dialogDelete.open}
              onClose={dialogDelete_onClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title">
                  {"Delete Coupon"}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Deleting this coupon ({dialogDelete?.name}) will not affect discounts for customers who have already redeemed the coupon, but new redemptions of the coupon won't be allowed.
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this coupon? This can't be undone.
                  </DialogContentText>

                  <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogDelete.errorMessage}</Typography>
                  
              </DialogContent>
              <DialogActions>
                  {dialogDelete.posting ? 
                      <Button variant="contained" color='error'>
                          <CircularProgress size={14}  sx={{ color: 'white' }}  />Delete Coupon
                      </Button>
                  :
                      <Button variant="contained" onClick={dialogDelete_onSubmit} autoFocus color='error'>
                        Delete Coupon
                      </Button>
                  }
                  <Button variant="outlined" color="inherit"  sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogDelete_onClose}>Close</Button>
                </DialogActions>
            </Dialog>   

    </Container>
    )

}

export default Coupons