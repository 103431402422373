

import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import backgroundImage from '../images/hero.jpg'; // Change the path to your image

import { Divider, Link, Grid, Card, CardMedia, CardContent, theme , Box  } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/system';
import imgGirlLift from '../images/IveTried.png'; // Change the path to your image
import imgWomenComp from '../images/womencomp.jpg'; // Change the path to your image
import imgLifeStyle from "../images/lifestyle.jpg"
import imgFood from "../images/food.jpg"
import imgPig from "../images/pig.jpg"
import imgDude from "../images/dude.jpg"
import useMediaQuery from '@mui/material/useMediaQuery';
import Copyright from '../components/Copyright';

const GuestHero = () => {
  return (
    <Container
    maxWidth={false}
    sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${backgroundImage})`, // Apply a dark overlay
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      textAlign: 'center',
      color: 'white',
      padding: '0', // Remove padding
      margin: '0', // Remove margin
    }}
  >
      <Typography
        variant="h2"
        sx={{
          mb: 0, // Adjusted marginBottom
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        Your Ultimate Fitness Partner
      </Typography>
      <Typography
        variant="h5"
        sx={{
          mb: 4, // Adjusted marginBottom
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
        }}
      >
        Expert Training, Personalized Plans, and a Thriving Community of Support
      </Typography>
    <Button component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer">
      Sign Up Now
    </Button>
  </Container>
  );
};


const StyledSection = styled('section')({
    margin: '2rem 0',
  });
  
  const StyledDivider = styled(Divider)({
    margin: '1rem 0',
  });
  
  const StyledHeading = styled(Typography)({
    margin: '1rem 0',
  });
  
  const StyledItalicHeading = styled(Typography)({
    margin: '1rem 0',
    fontStyle: 'italic',
  });
  
  const StyledText = styled(Typography)({
    margin: '1rem 0',
  });
  
  const PrimaryText = styled('span')({
    color: 'blue', // Replace with your desired color
    fontWeight: 'bold',
  });
  
  const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'black', // Replace with your desired color
    margin: '1rem 0',
    display: 'inline-flex',
    alignItems: 'center',
  });
  
  const StyledFontAwesomeIcon = styled(FontAwesomeIcon)({
    marginLeft: '0.5rem', // Adjust the spacing as needed
  });
  
  const ImageColumn = styled(Grid)({
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${imgGirlLift})`, // Replace with your image URL
      minHeight: '100%', // Make sure the column takes up the full height of the section
      display: 'flex',
      alignItems: 'center', // Vertically center the content
    });
  
  const GuestIveTried = () => {
  
      
  
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));
    const headingVariant =  useMediaQuery(theme => theme.breakpoints.down('md')) ? 'h4' : 'h3';
    const rowDirection =  useMediaQuery(theme => theme.breakpoints.down('md')) ? 'column-reverse' : 'row';
  
  
    return (
      <>
          <StyledSection>
              <Container maxWidth="lg">
                  <Grid container  alignItems="center" justifyContent="center" textAlign="center"  spacing={4} pb={3} >
                      <Grid item xs={12} md={12} >
                      <StyledDivider />
                          <StyledText>
                              Frustrated by the lack of visible progress? It's no secret that many personal trainers prioritize quantity over quality, resorting to generic diet plans consisting of the same monotonous chicken, broccoli, and rice. Such approaches aren't sustainable and fail to foster a lasting lifestyle change.
                          </StyledText>
                          <StyledText>
                              At our core, we construct enduring foundations and lifestyles tailored to seamlessly integrate with your unique way of living. Our meal plans are meticulously designed to maintain your well-being while allowing you to savor your daily life. Our exercise regimens are precision-engineered to optimize both your physical appearance and overall health.      </StyledText>
                          <StyledText>
                              Rest assured, our coaches exude confidence, underpinned by a track record of transformative successes with countless others.
                          </StyledText>
                          <StyledDivider />
                          <Button sx={{paddingBottom: '10px'}} component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer" >
                              Sign Up Now <StyledFontAwesomeIcon icon={faAngleRight} />
                          </Button>
                      </Grid>

                  </Grid>
              </Container>
          </StyledSection>
  
          <StyledSection  sx={{backgroundColor: (theme) => theme.palette.grey[300],padding: 0,margin: 0}}>
              <Box   sx={{ padding: 0, margin: 0, width: '100%'}} >
  
                  <Grid container spacing={0} sx={{padding: 0,  margin: 0,}} >
                      <Grid item xs={12} md={6}   container >
                          <img
                              src={imgLifeStyle} // Replace with your image URL
                              alt="Fitness Image"
                              style={{ width: '100%', height: 'auto' }}
                          />
                      </Grid>
                      <Grid item xs={12} md={6}  container alignItems="center" justifyContent="center" textAlign="center"  p={3} >
                          <Typography
                              variant={headingVariant}
                              sx={{
                              mb: 3, // Adjusted marginBottom
                              fontWeight: 'bold',
                              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                              }}
                          >Lifestyle Training</Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)'
                              }}
                          >
                           Our Lifestyle Training Plans are thoughtfully curated to harmonize seamlessly with your daily routines and aspirations. Each month, we design dynamic workout routines that evolve alongside your progress, fostering continuous growth and achievement. Alongside this, we offer personalized consultations that delve deep into your goals and preferences, ensuring that every aspect of your fitness journey is custom-tailored to your unique needs.
                          </Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                          But our commitment to your success extends beyond the individual workouts. We offer you a gateway to an inclusive and encouraging community, connecting you with like-minded individuals who share similar goals and aspirations. This supportive network provides not just motivation, but a space to exchange insights, celebrate milestones, and overcome challenges together. Our Lifestyle Training Plans empower you with flexibility and adaptability, enabling you to achieve remarkable results while still embracing the demands of your everyday life.
                          </Typography>
                          <StyledDivider />
                          <Button sx={{paddingBottom: '10px'}} component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer" >
                              Sign Up Now <StyledFontAwesomeIcon icon={faAngleRight} />
                          </Button>
                      </Grid>
  
                  </Grid>
              </Box>
          </StyledSection>
  
          <StyledSection  sx={{ padding: 0, margin: 0}}>
              <Box   sx={{ padding: 0, margin: 0, width: '100%'}} >
                  <Grid container spacing={0} sx={{padding: 0,  margin: 0}} direction={rowDirection}  >
  
                      <Grid item xs={12} md={6} container alignItems="center" justifyContent="center" textAlign="center"  p={3} >
                      <Typography
                              variant={headingVariant}
                              sx={{
                              mb: 3, // Adjusted marginBottom
                              fontWeight: 'bold',
                              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                              }}
                          >Competition Training</Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                           Our competition training plans are meticulously designed to propel you to the forefront with unparalleled precision and expertise. Our workout routines are tailored to sculpt your physique to its optimal form, harnessing your strengths while addressing areas of improvement. Our comprehensive posing sessions are geared towards honing your stage presence, ensuring you radiate confidence and grace during your performance.
                          </Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                          As the competition approaches, our peek week preparations are finely tuned to enhance your peak condition, allowing you to showcase your hard-earned physique at its zenith. Beyond physical training, our guidance extends to the day of the show, where we provide unwavering support to navigate any challenges that may arise.
                          </Typography>
                          <StyledDivider />
                          <Button sx={{paddingBottom: '10px'}} component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer" >
                              Sign Up Now <StyledFontAwesomeIcon icon={faAngleRight} />
                          </Button>
                      </Grid>
                      <Grid item xs={12} md={6}   container >
                          <img
                              src={imgWomenComp} // Replace with your image URL
                              alt="Fitness Image"
                              style={{ width: '100%', height: 'auto' }}
                          />
                      </Grid>
                  </Grid>
              </Box>
          </StyledSection>
  
          <StyledSection  sx={{backgroundColor: (theme) => theme.palette.grey[300],padding: 0,margin: 0}}>
              <Box   sx={{ padding: 0, margin: 0, width: '100%'}} >
  
                  <Grid container spacing={0} sx={{padding: 0,  margin: 0}} >
                      <Grid item xs={12} md={6}   container >
                          <img
                              src={imgFood} // Replace with your image URL
                              alt="Fitness Image"
                              style={{ width: '100%', height: 'auto' }}
                          />
                      </Grid>
                      <Grid item xs={12} md={6}  container alignItems="center" justifyContent="center" textAlign="center"  p={3} >
                          <Typography
                              variant={headingVariant}
                              sx={{
                              mb: 3, // Adjusted marginBottom
                              fontWeight: 'bold',
                              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                              }}
                          >Nutritional Planning </Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                              We provide meticulous nutritional guidance that encompasses crafting personalized meal plans aligned with your daily objectives. Our dedicated coaches maintain a vigilant eye on every aspect of your nutrition, constantly evaluating your caloric intake, macro distribution, and accommodating any nutritional or dietary restrictions.
                          </Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                              This comprehensive approach ensures that you're supported through every step of your journey, with our experts fine-tuning your nutritional strategy for sustained success.
                          </Typography>
                          <StyledDivider />
                          <Button sx={{paddingBottom: '10px'}} component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer" >
                              Sign Up Now <StyledFontAwesomeIcon icon={faAngleRight} />
                          </Button>
                      </Grid>
  
                  </Grid>
              </Box>
          </StyledSection>
  
  
          <StyledSection  sx={{ padding: 0, margin: 0}}>
              <Box   sx={{ padding: 0, margin: 0, width: '100%'}} >
                  <Grid container spacing={0} sx={{padding: 0,  margin: 0}} direction={rowDirection} >
  
                      <Grid item xs={12} md={6} container alignItems="center" justifyContent="center" textAlign="center"  p={3} >
                      <Typography
                              variant={headingVariant}
                              sx={{
                              mb: 3, // Adjusted marginBottom
                              fontWeight: 'bold',
                              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                              }}
                          >Value</Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                           In these challenging times, we understand the constraints on your budget and the importance of prioritizing your well-being. While financial pressures may be a reality, neglecting your health isn't an option we endorse.
                          </Typography>
                          <Typography
                              variant="body1"
                              sx={{
                              mb: 4, // Adjusted marginBottom
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                              }}
                          >
                          Consider this: the national average hourly rate for personal training hovers around $60, requiring a significant investment for each one-hour session. At Team Slay, we offer a transformative alternative. For the approximate cost of three personal training sessions, you'll gain access to 25 hourly workout sessions, invaluable nutrition guidance, and personalized one-on-one consultations. This equitable approach underscores our commitment to making top-tier fitness and holistic wellness attainable for everyone, without compromising on the quality of support and expertise you receive.
                          </Typography>
                          <StyledDivider />
                          <Button sx={{paddingBottom: '10px'}} component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer" >
                              Sign Up Now <StyledFontAwesomeIcon icon={faAngleRight} />
                          </Button>
                      </Grid>
                      <Grid item xs={12} md={6}   container >
                          <img
                              src={imgPig} // Replace with your image URL
                              alt="Fitness Image"
                              style={{ width: '100%', height: 'auto' }}
                          />
                      </Grid>
                  </Grid>
              </Box>
          </StyledSection>
          <Container
      maxWidth={false}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${imgDude})`, // Apply a dark overlay
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        textAlign: 'center',
        color: 'white',
        padding: '0', // Remove padding
        margin: '0', // Remove margin
      }}
    >
        <Typography
          variant="h3"
          sx={{
            mb: 4, // Adjusted marginBottom
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
        >
            A vision of the future creates a passion in the present.
        </Typography>
      
      <Button component="a" href="/auth/signup" variant="contained" color="primary" size="large" rel="noopener noreferrer">
        Sign Up Now
      </Button>
    </Container>
    
    <StyledSection  sx={{backgroundColor: (theme) => theme.palette.grey[300],padding: 0,margin: 0}}>
      <Container sx={{display: 'flex',flexDirection: 'column', alignItems: 'center',}}>
      <Copyright></Copyright>

      </Container>
    </StyledSection>
      </>
    );
  };


function Index() {

    return(<>
        <GuestHero />
        <GuestIveTried></GuestIveTried>
    </>)
}


export default Index