import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import UsersIcon from '@mui/icons-material/Group';
import LogInIcon from '@mui/icons-material/Login';
import LogOutIcon from '@mui/icons-material/Logout';

import { useNavigate } from 'react-router-dom';
//import Link from '@mui/material/Link';
import { AuthContext } from '../AuthContext';
import Button from '@mui/material/Button'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
function Navbar() {
  const { user, getUser, signOut, isAuthenticated } = useContext(AuthContext);

  const [isauth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {

    setIsAuth(isAuthenticated());
  }, [user]);

  const goHome = () => {
    navigate('/home');
  };

  const goSignIn = () => {
    navigate('/auth/signin');
  };

  return (
    <AppBar component="nav" sx={{ backgroundColor:  (theme) => theme.palette.primary.contrastText, color:  (theme) => theme.palette.text.primary  }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <Link to={isauth ? "/home" : "/"}>
          <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
            <img
              width="63"
              height="39"
              src="https://teamslay.blob.core.windows.net/web/media/logo/tslogoblack.svg"
              alt="Logo"
            />
          </IconButton>
        </Link>

        {isauth ? (
            <Button endIcon={ <LogoutTwoToneIcon />} size="medium" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2,  color: (theme) => theme.palette.primary.main, textTransform: "none"  }} onClick={() => signOut()} >
                Sign out
            </Button>
        ) : (
            <Button endIcon={<LoginTwoToneIcon />} size="medium" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2,  color: (theme) => theme.palette.primary.main, textTransform: "none"  }} onClick={goSignIn} >
                Sign in
            </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
