
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useApi } from '../../../hooks/useApi'
import { DataGrid, getGridStringOperators, } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import MuiLink from '@mui/material/Link'
import { OtherHouses } from '@mui/icons-material';

function Users() {

  //Data-Grid State - Store in Local Storage 
  const defaultPagination = { pageSize: 10, page : 0}
  const [paginationModel, setPaginationModel] =  useState(() => {
    const storedPaginationModel = localStorage.getItem('paginationModel');
    return storedPaginationModel ? JSON.parse(storedPaginationModel) : defaultPagination;
  });
  
  useEffect(() => {
    window.localStorage.setItem('paginationModel', JSON.stringify(paginationModel));
  }, [paginationModel]);

  const defaultSort = [];
  const [sortModel, setSortModel] = useState(() => {
    const storedSortModel = localStorage.getItem('sortModel');
    return storedSortModel ? JSON.parse(storedSortModel) : defaultSort;
  });

  useEffect(() => {
    window.localStorage.setItem('sortModel', JSON.stringify(sortModel));
  }, [sortModel]);

  const defaultFilter = {"items" : []}
  const [filterModel, setFilterModel] = useState(() => {
    const storedFilterModel = localStorage.getItem('filterModel');
    return storedFilterModel ? JSON.parse(storedFilterModel) : defaultFilter;
  });

  useEffect(() => {
    window.localStorage.setItem('filterModel', JSON.stringify(filterModel));
  }, [filterModel]);

  const [activeOnly, setActiveOnly] = useState(() => {
    const storedActiveOnly= localStorage.getItem('activeOnly');
    var rtnActiveOnly = true;
    if(storedActiveOnly !== null) {
      rtnActiveOnly = storedActiveOnly === 'true';
    }
    return rtnActiveOnly;
  });
  
  useEffect(() => {
    window.localStorage.setItem('activeOnly', activeOnly);
  }, [activeOnly]);


  const jApi = useApi();

  const [isloadingGrid, setIsLoadingGrid] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);

  const fetchData = React.useCallback(async (fm, pm, sm, ao) => {
    try {
      setIsLoadingGrid(true);
      setErrorMessage();
      const page_size = pm.pageSize; // Set your desired page size
      let page = pm.page + 1;
      const responseUsers = await jApi.request("teamslay/user/list", {  "active_only" : ao, "page_number" : page,  "page_size" : page_size, "filter_username" : fm.items.find(item => item.field === 'username')?.value || null, "filter_name" : fm.items.find(item => item.field === 'name')?.value || null, "sort_column" : sm[0]?.field || null, "sort_order": sm[0]?.sort || null },);
      setRows(responseUsers.data.result.data); 
      setRowCount(responseUsers.data.result.total_count); 
    } catch (error) {
      setErrorMessage('Error: ' + error);
    } finally {
      setIsLoadingGrid(false);
    }
  }, []); 

  useEffect(() => {
    fetchData(filterModel, paginationModel, sortModel, activeOnly); // Fetch the first page initially
  }, [fetchData]);    

  const handlePaginationChange = async (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    await fetchData(filterModel, newPaginationModel, sortModel, activeOnly);
  };

  const handleSortModelChange =  async (newSortModel) => {
    setSortModel(newSortModel);
    await fetchData(filterModel, paginationModel, newSortModel, activeOnly);
  };

  const handleFilterModelChange = async (newFilterModel) => {
    setFilterModel(newFilterModel);
    await fetchData(newFilterModel, paginationModel, sortModel, activeOnly);
  };

  const handleActiveOnly = async (event) => {
    setActiveOnly(event.target.checked);
    setPaginationModel(defaultPagination);
    setFilterModel(defaultFilter);
    setSortModel(defaultSort);
    await fetchData(defaultFilter, defaultPagination, defaultSort, event.target.checked);
  };


    // #region New User

    const [dialogNew, setDialogNew] = useState({
      open: false,
      loading: false,
      posting: false,
      errorMessage: '',
      first : '',
      last : '',
      email : '',
      password : '',
      first_error: false,
      last_error: false,
      email_error: false,
      password_error: false,
  });
  
  const dialogNew_onOpen = async (payment_id) => {
      setDialogNew((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: '',      
        first : '',
        last : '',
        email : '',
        password : '',
        first_error: false,
        last_error: false,
        email_error: false,
        password_error: false,}));
  };

  const dialogNew_onClose =  () => {
      setDialogNew((prevState) => ({...prevState, open: false,       
        first : '',
        last : '',
        email : '',
        password : '',
        first_error: false,
        last_error: false,
        email_error: false,
        password_error: false}));
  };

  const dialogNew_ValidateForm = () => {

    setDialogNew((prevState) => ({...prevState,
      first_error: dialogNew.first === '',
      last_error: dialogNew.last === '',
      email_error: !/\S+@\S+\.\S+/.test(dialogNew.email),
      password_error: dialogNew.password.length < 6,
    }));

    return !(dialogNew.first === '' || dialogNew.last === '' || !/\S+@\S+\.\S+/.test(dialogNew.email) || dialogNew.password.length < 6);
  };

  const dialogNew_onSubmit = async () => {
      const { posting, email , first, last, password } = dialogNew
      if(posting) {
          return
      }
  
      try {
          setDialogNew((prevState) => ({...prevState, posting: true }));

          const isValid = dialogNew_ValidateForm();
          console.log(isValid)

          if(isValid) {

            //Need to create the TeamSlay User
            const responseUser = await jApi.request("teamslay/user/post", { username : email, first : first, last : last, password : password });
            var teamslay_user = responseUser.data.result
            //Create/Get the Stripe Customer
            const responseStripe = await jApi.request("stripe/customer/post", { email : email, name : first + ' ' + last});
            var stripe_customer = responseStripe.data.result
            //Set stripe_customerid and Activate Customer
            const responseUsers = await jApi.request("teamslay/user/put", { id : teamslay_user.id, stripe_customerid : stripe_customer.id, active : true });

            setDialogNew((prevState) => ({...prevState, open: false }));
            await fetchData(filterModel, paginationModel, sortModel, activeOnly); 
  
          }

      } catch (error) {
          setDialogNew((prevState) => ({...prevState, errorMessage: error }));
      } finally {
          setDialogNew((prevState) => ({...prevState, posting: false }));
      }
  };

  const dialogNew_onInputChange = (e) => {
    const { name, value } = e.target;
    setDialogNew((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // #endregion




  const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

  return(
    <Container  maxWidth="md" component="main" >
      <Grid container spacing={0}  sx={{ pt: 4, alignContent: 'left', textAlign: 'left', }} >
        <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Users</Typography>
            </Breadcrumbs>
        </Grid>
        <Grid item xs={12}  sx={{pt: 1, }}>
            <Typography  variant="h5"> Users</Typography>
        </Grid>
        <Grid item xs={12} sx={{   paddingTop: '0px' }} >
        <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'space-between',}} >
          <FormControlLabel control={<Checkbox
            size="small"
            checked={activeOnly}
            onChange={handleActiveOnly}
            inputProps={{ 'aria-label': 'controlled' }}
          /> } label="Active Only?"  />
            <Button  variant="text" onClick={() => dialogNew_onOpen()} >+ ADD User</Button>
          </Stack>
        </Grid>

        <Grid item  sx={{ width: '100%' }}>
          <DataGrid
            loading={isloadingGrid}
            rows={rows}
            columns={[

              { field: 'active', headerName: 'Active', 
              width: 65,             
              sortable : false,
              filterable : false,
              renderCell: (params) => ( <> { params.row.active ? <CheckBoxIcon sx={{   color: (theme) => theme.palette.primary.main,}}></CheckBoxIcon> : <CheckBoxOutlineBlankIcon sx={{   color: (theme) => theme.palette.grey[700]}}></CheckBoxOutlineBlankIcon> } </>)
            },
              { field: 'username', headerName: 'Username', width: 250, filterOperators: stringOperators, 
                renderCell: (params) => (
                  <Link to={`/admin/users/${params.row.id}`} style={{ textDecoration: 'none' }}>
                    {params.value}
                  </Link>
                ),
              
              },
              { field: 'name', headerName: 'Name', width: 200 },
              { field: 'created', headerName: 'Created', 
                flex: 1,   
                sortable : true,
                filterable : false,
                valueFormatter: (params) => {
                  // Format the date using Intl.DateTimeFormat or any other date formatting library
                  const formattedDate = new Date(params.value).toLocaleDateString('en-US');
                  return formattedDate;
                },
              },
              { field: 'roles', headerName: 'Roles', width: 150,
              renderCell: (params) => (
              <>
                {params.value?.map((item) => (
                     <Chip  color='primary' label={item} size="small" />
                ) )}
                </>
              ) 
            },
            ]}
           
            pagination
            paginationMode='server'
            pageSizeOptions={[10, 25, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            rowCount={rowCount}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            />
                
        </Grid>
        <Grid item sm={12}>
          <Typography  variant="body1" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>
        </Grid>
      </Grid>

      
      <Dialog open={dialogNew.open}
          onClose={dialogNew_onClose} 
          aria-labelledby="alert-dialog-title" 
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
              {"Add new user"}
          </DialogTitle>
          <DialogContent>

                <Stack>
                <Box component="form" sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={dialogNew_onInputChange}
                        value={dialogNew.first}
                        error={dialogNew.first_error} // Apply error styling if formErrors.first is true
                        helperText={dialogNew.first_error ? 'First name is required' : ''}
                        autoComplete="given-name"
                        name="first"
                        required
                        fullWidth
                        id="first"
                        label="First Name"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={dialogNew_onInputChange}
                        value={dialogNew.last}
                        error={dialogNew.last_error} // Apply error styling if formErrors.first is true
                        helperText={dialogNew.last_error ? 'Last name is required' : ''}
                        required
                        fullWidth
                        id="last"
                        label="Last Name"
                        name="last"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={dialogNew_onInputChange}
                        value={dialogNew.email}
                        error={dialogNew.email_error} // Apply error styling if formErrors.first is true
                        helperText={dialogNew.email_error ? 'Invalid E-mail Address' : ''}
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={dialogNew_onInputChange}
                        value={dialogNew.password}
                        error={dialogNew.password_error} // Apply error styling if formErrors.first is true
                        helperText={dialogNew.password_error ? 'Invalid Password' : ''}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                      />
                    </Grid>
                  </Grid>
                 
                </Box>
                <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogNew.errorMessage}</Typography>
                </Stack>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>                    
          </DialogContent>
          <DialogActions>
              {dialogNew.posting ? 
                  <Button variant="contained" >
                      <CircularProgress size={14}  sx={{ color: 'white' }} /> Add User
                  </Button>
              :
                  <Button variant="contained" onClick={dialogNew_onSubmit} autoFocus>
                      Add User
                  </Button>
              }
              <Button  variant="outlined"  color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogNew_onClose}>Close</Button>
          </DialogActions>
      </Dialog>   


    </Container>
  
  )
}

export default Users

