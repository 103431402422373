import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import LinearProgress from '@mui/material/LinearProgress';

import { useApi } from './hooks/useApi'

const RequireAuth = ({ children, roles }) => {

  const [userProfile, setUserProfile] = useState(null);
  const { user, isAuthenticated } = useContext(AuthContext);
  const [isloading, setIsLoading] = useState(true);

  const jApi = useApi();

  const navigate = useNavigate(); 

  useEffect(() => {
    //console.log('here')
    // Check authentication when the component mounts
    if (!isAuthenticated()) {
      // Redirect to login page
      navigate("/home")
    }

    const setProfile = async () => {
      try {
        const response = await jApi.request("auth/profile", { })
        setUserProfile(response.data.result)
        //console.log(response.data.result)
      } catch (error) {
        setErrorMessage(error)
      }
    }
    setProfile();
  }, [user]); // Add "user" as a dependency


  useEffect(() => {
    if (userProfile !== null && userProfile.roles !== null) {
      const userRoles = userProfile.roles || [];
      const hasMatchingRole = userRoles.some(userRole => roles.includes(userRole));
      const isEmptyKeys = Object.keys(userProfile).length === 0;

      if (!hasMatchingRole && roles.length > 0  || isEmptyKeys) {
        navigate("/");
      } else {
        setIsLoading(false); // Set loading to false only if the roles match
      }
    }
  }, [userProfile]);


  if(isloading){
    return(
      <LinearProgress /> 
    )
  } 

  return children;
};

export default RequireAuth;
