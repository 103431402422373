import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import { useApi } from '../../../hooks/useApi'
import { Breadcrumbs,Container, Grid, Typography } from '@mui/material'

import SlideSubscription from './SlideSubscription';
import SlidePaymentMethod from './SlidePaymentMethod';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

function Index() {


    const jApi = useApi();
    const [errorMessage, setErrorMessage] = useState();

    const [teamslayUser,setTeamslayUser] = useState(null)

    const fetchData = async () => {    
        try {
            setErrorMessage("")
            //Get the requesting user based on API Token
            const responsets = await jApi.request("teamslay/user/get", {} )
            const teamslay_user = responsets.data.result
            setTeamslayUser(teamslay_user)

        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return(
        <Container  maxWidth="md" component="main" >
            <Grid container spacing={0}  sx={{ pt: 4, alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">{teamslayUser?.username}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sx={{pt: 1, pb: 2}}>
                    <Typography  variant="h5"> My Subscriptions</Typography>
                </Grid>
                <Grid item xs={12} sx={{pb: 2}}>
                    {teamslayUser !== null ?  <SlideSubscription teamslay_user={teamslayUser}></SlideSubscription> : null}
                </Grid>
                <Grid item xs={12} >
                    {teamslayUser !== null ?  <SlidePaymentMethod teamslay_user={teamslayUser}></SlidePaymentMethod> : null}
                </Grid>
                <Grid item xs={12}  sx={{pt: 1}} >
                    <Typography  variant="body1" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>
                </Grid>
            </Grid>
        </Container>
    
    )
}


export default Index