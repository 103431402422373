import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';


function Copyright() {
    return (
      <Stack direction="row" spacing={1}  color="text.secondary">
        <Typography variant="body2" color="inherit" align="center" >Copyright ©</Typography>
        <Link color="inherit" href="/home" variant="body2"> 
          Team Slay llc.
        </Link>{' '}
        <Typography variant="body2" color="inherit" align="center" >
        {new Date().getFullYear()}
        </Typography>
        <Link color="inherit" href="/terms" variant="body2" >
          Terms
        </Link>{' '}
        <Link color="inherit" href="/privacy" variant="body2" >
          Privacy
        </Link>
      </Stack>
     
    );
  }
  
export default Copyright