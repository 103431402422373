

import { Avatar, Box, Button,  Card, CardActions, CardContent, CardHeader, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import Copyright from '../../components/Copyright'
import { List, ListItem, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTheme } from '@mui/material/styles';



function Terms() {
    
    const theme = useTheme();
    const getIcon = (name) =>{
        if(name == 'trophy'){
          return icon({ prefix: 'fas', name: 'trophy'});
        }
        if(name == 'dumbbell'){
          return icon({ prefix: 'fas', name: 'dumbbell'});
        }
        if(name == 'check'){
          return icon({ prefix: 'fas', name: 'check'});
        }
        return icon({ prefix: 'fas', name: 'circle-question'});
    }
    return(

        <Container  maxWidth="md" component="main" sx={{ pt: 4, pb: 4,  alignContent: 'left',  textAlign: 'left' }}>
            <Typography  variant="h4" >
                Terms and Conditions
            </Typography>
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>
            Welcome to Team Slay!
            </Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            These terms and conditions outline the rules and regulations for the use of Team Slay's Website, located at www.teamslay.fit.
            </Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            By accessing this website, we assume you accept these terms and conditions. Do not continue to use Team Slay if you do not agree to take all of the terms and conditions stated on this page.
            </Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
                The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: "Client", "You", and "Your" refer to you, the person logging on this website and compliant with the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our," and "Us" refer to our Company. "Party", "Parties", or "Us" refer to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to the prevailing law of the Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Minimum Subscription Period</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
                Subscriptions to Team Slay services are for a minimum period of 3 months. Clients are required to maintain their subscription for the entire duration of the minimum subscription period.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Injury Disclaimer</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
                Team Slay is not liable for any injuries sustained during or as a result of participating in our training programs. Clients are advised to consult with a medical professional before starting any fitness program and should exercise caution and proper form during workouts to minimize the risk of injury.
            </Typography>


            
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>License</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Unless otherwise stated, Team Slay and/or its licensors own the intellectual property rights for all material on Team Slay. All intellectual property rights are reserved. You may access this from Team Slay for your own personal use subjected to restrictions set in these terms and conditions.

You must not:
</Typography>

<List>
                <ListItem >
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >Republish material from Team Slay</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >Sell, rent or sub-license material from Team Slay</Typography>
                    </ListItemText>

                </ListItem>
                <ListItem>
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})}  style={{ color: theme.palette.primary.main, fontSize: '8px'  }}/>
                <ListItemText sx={{pl: 2}}  >
                <Typography variant='body2' >Reproduce, duplicate or copy material from Team Slay</Typography>
                </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >Redistribute content from Team Slay</Typography>
                    </ListItemText>
                </ListItem>
            </List>



<Typography variant='body1'  sx={{pt:2, pb:2 }}>
This Agreement shall begin on the date hereof.

Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Team Slay does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Team Slay,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Team Slay shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.

Team Slay reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.

You warrant and represent that:
</Typography>


            <List>
                <ListItem >
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2, }}>
                    <Typography variant='body2' >
                    You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
                    </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}}  >
                    <Typography variant='body2' >The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})}  style={{ color: theme.palette.primary.main, fontSize: '8px'  }}/>
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}}  >
                    <Typography variant='body2' >The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            You hereby grant Team Slay a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Cookies</Typography>

            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            We employ the use of cookies. By accessing Team Slay, you agree to use cookies in agreement with the Team Slay's Privacy Policy.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>iFrames</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
            </Typography>


            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Content Liability</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
            </Typography>


            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Your Privacy</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Please read Privacy Policy
            </Typography>


            
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Reservation of Rights</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Disclaimer</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
            </Typography>

            <List>
                <ListItem >
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >limit or exclude our or your liability for death or personal injury;</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >limit or exclude our or your liability for fraud or fraudulent misrepresentation;</Typography>
                    </ListItemText>

                </ListItem>
                <ListItem>
                    <FontAwesomeIcon  icon={icon({ prefix: 'fas', name: 'circle'})}  style={{ color: theme.palette.primary.main, fontSize: '8px'  }}/>
                <ListItemText sx={{pl: 2}}  >
                <Typography variant='body2' >limit any of our or your liabilities in any way that is not permitted under applicable law; or</Typography>
                </ListItemText>
                </ListItem>
                <ListItem>
                    <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'circle'})} style={{ color: theme.palette.primary.main, fontSize: '8px' }} />
                    <ListItemText sx={{pl: 2}} >
                    <Typography variant='body2' >exclude any of our or your liabilities that may not be excluded under applicable law.</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
                The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph, and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.
            </Typography>

            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
            </Typography>

            
            <Grid sx={{pt:2,display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
              <Copyright />
            </Grid> 

        </Container>

    )

}


export default Terms