
import React,  { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom'; // Import Link from React Router

import Container from "@mui/material/Container"
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid'
import Skeleton from "@mui/material/Skeleton"
import { Link } from 'react-router-dom'; // Import Link from React Router

import Typography from '@mui/material/Typography';
import { useApi } from '../../../hooks/useApi'
import { useTheme } from '@mui/material/styles';

import {loadStripe} from '@stripe/stripe-js';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'
import SlideSubscription from  './SlideSubscription'
import SlidePaymentMethod from  './SlidePaymentMethod'
import { v4 as uuidv4 } from 'uuid';

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';


function UserDetail() {

      
    const { userId } = useParams();

    const [isloading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
   
    const [userInfo, setUserInfo] = useState({ username: ""});

    const jApi = useApi();


    const fetchUser = React.useCallback(async (page) => {
        try {
            setErrorMessage("")
            const responseUsers = await jApi.request("teamslay/user/get", {userid : userId });
            const users = responseUsers.data.result;
            setUserInfo(users)
        } catch (error) {
            setErrorMessage('Error: ' +  error);
        } finally {
            setIsLoading(false);
        }
    }, [ ]);
      
    useEffect(() => {
        fetchUser(); // Fetch the first page initially
    }, []);   

    const handleActiveChange =  (event) => {
        try {
           const responseUsers =  jApi.request("teamslay/user/put", {id : userInfo.id, active: event.target.checked });
           userInfo.active = event.target.checked
           setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                active: event.target.checked
            }));
        } catch (error) {
            setErrorMessage('Error: ' +  error);
        }
    };
    const handleAdminChange =  (event) => {
        try {
            let newRoles = userInfo.roles
            if(event.target.checked) {
                newRoles = [ ...userInfo.roles, 'admin']
            } else {
                newRoles = userInfo.roles.filter((role) => role !==  'admin')
            }
            console.log(newRoles)
            const responseUsers =  jApi.request("teamslay/user/put", {id : userInfo.id, roles: newRoles });
           setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                roles: newRoles
            }));
        } catch (error) {
            setErrorMessage('Error: ' +  error);
        }
    };
    return(<>
        
        <Container  maxWidth="md" component="main" sx={{ pt: 4, pb: 4 }}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                <Link to={`/admin/users`}>Users</Link>
                <Typography color="text.primary">{userInfo.username ? userInfo.username: <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}</Typography>
            </Breadcrumbs>            
            <Typography  variant="h4"  >
                {userInfo.first}  {userInfo.last}
            </Typography>
            {userInfo.username !== "" ?  
                <FormControlLabel control={<Checkbox
                    checked={userInfo.active}
                    onChange={handleActiveChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    /> } label="Active" />
            : null }
            {userInfo.username !== "" ?  
                            <FormControlLabel control={<Checkbox
                                checked={userInfo.roles.includes('admin')}
                                onChange={handleAdminChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                /> } label="Admin" />
            : null }
            <Grid container spacing={2}>
                <Grid item  md={12}>
                    {userInfo.username !== "" ?  <SlideSubscription teamslay_user={userInfo}></SlideSubscription> : null}
                </Grid>
                <Grid item  md={12}>
                    {userInfo.username !== "" ?  <SlidePaymentMethod teamslay_user={userInfo}></SlidePaymentMethod> : null}
                </Grid>
                <Grid item sm={12}>
                    <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>
                </Grid>
            </Grid>

            
        </Container>

  </>)

}

export default UserDetail