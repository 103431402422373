
import React, { useState, useEffect } from 'react';
import {Avatar,CircularProgress,Divider,Paper,Skeleton,Stack,Typography} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useApi } from '../../../hooks/useApi'
import { faCcVisa, faCcMastercard, faCcAmex,  faCcJcb, faCcDiscover } from "@fortawesome/free-brands-svg-icons"
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@mui/material';
import {Button,ButtonGroup} from '@mui/material'
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function SlidePaymentMethod({teamslay_user, setSubscriptionRefreshKey}) {

    const [isloading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [paymentMethods,setPaymentMethods] = useState([]);

    const jApi = useApi();

    const fetchData = async () => {    
        try {
            setErrorMessage("")
            setIsLoading(true)

            if(teamslay_user === null) {
                return null;
            }
            const responsePM = await jApi.request("stripe/payment_method/list", { "customer_id" : teamslay_user.stripe_customerid })
            setPaymentMethods(responsePM.data.result.data)
            
        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
            setIsLoading(false)
        }
    }
    

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);


    const getCardIcon = (name) =>{
        name = name.toLowerCase();
        if(name == 'visa'){
          return faCcVisa;
        }
        if(name == 'mastercard'){
          return faCcMastercard;
        }
        if(name == 'jcb'){
          return faCcJcb;
        }
        if(name == 'discover'){
          return faCcDiscover;
        }
        if(name == 'amex'){
          return faCcAmex;
        }
        return icon({ prefix: 'fab', name: 'credit-card'});
    }

    const handleAddCardClick = async () => {
        try {
            const return_url =  window.location.href;
            const checkout_session = await jApi.request("stripe/checkout/post", {customer_id : teamslay_user.stripe_customerid, success_url : return_url, cancel_url : return_url, mode:"setup" })
            window.location.href = checkout_session.data.result.url;
        } catch (error) {
            setErrorMessage("Error: " + error)
        }
    };



    // #region Cancel Dialog

    const [dialogCancel, setDialogCancel] = useState({
        open: false,
        loading: false,
        posting: false,
        errorMessage: '',
        payment_id : ''
    });
    
    const dialogCancel_onOpen = async (payment_id) => {
        setDialogCancel((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: '', payment_id : payment_id}));
    };

    const dialogCancel_onClose =  () => {
        setDialogCancel((prevState) => ({...prevState, open: false, payment_id : ''}));
    };

    const dialogCancel_onSubmit = async () => {
        const { posting, payment_id } = dialogCancel
        if(posting) {
            return
        }
    
        try {
            setDialogCancel((prevState) => ({...prevState, posting: true }));
            if (payment_id.startsWith("pm_")) {
                const responseStripe = await jApi.request("stripe/payment_method/delete", { "customer_id" : teamslay_user.stripe_customerid, "payment_method" : payment_id })
            } else if (payment_id.startsWith("card_")) {
                const responseStripe = await jApi.request("stripe/card/delete", { "customer_id" : teamslay_user.stripe_customerid, "card_id" : payment_id })
            }
            setDialogCancel((prevState) => ({...prevState, open: false }));
            await fetchData();
            setSubscriptionRefreshKey();
        } catch (error) {
            setDialogCancel((prevState) => ({...prevState, errorMessage: error }));
        } finally {
            setDialogCancel((prevState) => ({...prevState, posting: false }));
        }
    };

    // #endregion


  
    return(
        <>
            <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'space-between',}} >
                <Stack  direction="row" spacing={2}  alignItems="center">
                    <Avatar
                        sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        width: '22px',
                        height: '22px',
                        }}
                    >
                        <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'lock'})}  style={{ fontSize: '12px' }} />
                    </Avatar>
                    <Typography variant="h6" mb={0} color="textPrimary" align="center">Payment Methods</Typography>
                </Stack>
                <Button  variant="text" onClick={() => handleAddCardClick()}>+ ADD</Button>
            </Stack>

            <Divider></Divider>  

            <TableContainer  component={Paper}  sx={{ marginTop: '16px' }}>
                <Table  aria-label="simple table"  size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Brand</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Expires</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { isloading !== false ? 
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell colSpan={4}  component="th" scope="row">
                                <Skeleton /> 
                            </TableCell>
                        </TableRow>
                    : 
                    <>
                    { paymentMethods?.map((item) => (
                         <TableRow  key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row" sx={{color: (theme) => theme.palette.primary.main }} >
                                <FontAwesomeIcon  style={{ fontSize: '25px'}}   icon={getCardIcon(item.card.brand)} />
                            </TableCell>
                            <TableCell>
                                { item.id === "" ? <>{item.card.last4}</> : <>.... .... .... {item.card.last4}</>} 
                                
                            </TableCell>
                            <TableCell >
                            { item.id === "" ? <></> : <> {item.card.exp_month}/{item.card.exp_year}</>} 
                               
                            </TableCell>
                            <TableCell align="right">
                            { item.id === "" ? <></> : 
                                <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
                                    <IconButton size="small" aria-label="delete"  onClick={() => dialogCancel_onOpen(item.id)} sx={{color: (theme) => theme.palette.primary.main }}>
                                        <DeleteIcon  fontSize="inherit"/>
                                    </IconButton>   
                                </ButtonGroup>
                             
                            } 
                            </TableCell>
                        </TableRow>
                        )

                    )}
                    </>
                }
                </TableBody>
                </Table>
            </TableContainer>

            <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>

            <Dialog open={dialogCancel.open}
                onClose={dialogCancel_onClose} 
                aria-labelledby="alert-dialog-title" 
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Remove Payment Method"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Remove this payment method?  Once removed it can no longer be used for a payment or re-attached to a Customer.
                      <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogCancel.errorMessage}</Typography>
                    </DialogContentText>                    
                </DialogContent>
                <DialogActions>
                    {dialogCancel.posting ? 
                        <Button  variant="contained" color='error'>
                            <CircularProgress size={14}   sx={{ color: 'white' }}  />Delete Payment
                        </Button>
                    :
                        <Button  variant="contained" onClick={dialogCancel_onSubmit} autoFocus color='error'>
                            Delete Payment
                        </Button>
                    }
                    <Button  variant="outlined"  color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogCancel_onClose}>Close</Button>
                </DialogActions>
            </Dialog>   

        </>
    )
}

export default SlidePaymentMethod