import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

import Index from './pages/Index';
import Terms from './pages/Home/Terms';
import Privacy from './pages/Home/Privacy';

import Navbar from './components/Navbar';
import Home from './pages/Home/Index'
import SignIn from './pages/Auth/SigIn'
import SignUp from './pages/Auth/SignUp'
import ForgotPassword from './pages/Auth/ForgotPassword';
import RequireAuth from './RequireAuth';

import AdminUser from "./pages/Admin/User/Index"
import AdminUserDetail from "./pages/Admin/User/UserDetail"

import AdminInvoice from "./pages/Admin/Invoice/Index"
import AdminCoupon from "./pages/Admin/Coupon/Index"
import AdminCouponDetail from "./pages/Admin/Coupon/CouponDetail"

import UserSubscription from './pages/User/Subscription/Index'
import UserInvoice from './pages/User/Invoice/Index'

const App = () => {

    useEffect(() => {
        const adjustBodyPadding = () => {
          const appBarHeight = document.querySelector('nav').offsetHeight;
          document.body.style.paddingTop = `${appBarHeight}px`;
        };
    
        adjustBodyPadding();
        window.addEventListener('resize', adjustBodyPadding);
    
        return () => {
          window.removeEventListener('resize', adjustBodyPadding);
        };
      }, []);

      
 return (
    <Router >
        <Navbar />
        <Routes>
            <Route path="/" element={<Index />}>
                <Route index element={<Index />} />
                <Route path="contact" element={<Index />} />
                <Route path="*" element={<Index />} />
            </Route>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            
            <Route path="/auth/signin" element={<SignIn />} />
            <Route path="/auth/signup" element={<SignUp />} />
            <Route path="/auth/forgotpassword" element={<ForgotPassword /> } />
            
            <Route path={'/home'} element={
                <RequireAuth roles={[]} >
                    <Home />
                </RequireAuth>
            }/>
            <Route path={'/user/subscription'} element={
                <RequireAuth roles={[]} >
                    <UserSubscription />
                </RequireAuth>
            }/>
            <Route path={'/user/Invoice'} element={
                <RequireAuth roles={[]} >
                    <UserInvoice />
                </RequireAuth>
            }/>

            <Route path={'admin/users'} element={
                <RequireAuth roles={"admin"}>
                      <AdminUser />
                </RequireAuth>
            }/>
            <Route path={'admin/users/:userId'} element={
                <RequireAuth roles={"admin"}>
                      <AdminUserDetail />
                </RequireAuth>
            }/>
            <Route path={'admin/invoices'} element={
                <RequireAuth roles={"admin"}>
                      <AdminInvoice />
                </RequireAuth>
            }/>
            <Route path={'admin/coupons'} element={
                <RequireAuth roles={"admin"}>
                      <AdminCoupon />
                </RequireAuth>
            }/>
            <Route path={'admin/coupons/:couponId'} element={
                <RequireAuth roles={"admin"}>
                      <AdminCouponDetail />
                </RequireAuth>
            }/>
        </Routes>
    </Router>
 );
 };

export default App;