import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/Copyright'
import LinearProgress from '@mui/material/LinearProgress';

import { useNavigate } from "react-router-dom"
import { useState, useContext } from "react"
import { useApi } from '../../hooks/useApi'
import { AuthContext } from '../../AuthContext';

function SignUp() {

  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errmsg, setErrMsg] = useState(null);
  const [isloading, setIsLoading] = useState(false);

  const jApi = useApi();

  const handleSubmit = async (event) => {

    event.preventDefault();
    if(isloading) {
      return;
    }
    setIsLoading(true);
    setErrMsg(null);

    const formData = new FormData(event.target);
  
    // Access form field values using formData.get(fieldname)
    const first = formData.get('first');
    const last = formData.get('last');
    const username = formData.get('email');
    const password = formData.get('password');

    try {

      const registerResponse = await jApi.request("auth/register", { "username" : username, "password" : password, "first" : first, "last" : last } )

      if(registerResponse.data.error) {
        setErrMsg(registerResponse.data.error["message"]);
        setIsLoading(false);
        return
      }
  
      const accessTokenResponse = await jApi.request("auth/accesstoken", { "username" : username, "password" : password } )
  
      if(accessTokenResponse.data.error) {
        setErrMsg(accessTokenResponse.data.error["message"]);
        setIsLoading(false);
        return
      }
  
      signIn(accessTokenResponse.data.result.access_token, accessTokenResponse.data.result.access_expire, accessTokenResponse.data.result.refresh_token, accessTokenResponse.data.result.refresh_expire,accessTokenResponse.data.result.user_info);
      navigate("/home")

    }
    catch(err) {
      console.log(err)
      setErrMsg(err);
    }
    finally {
      setIsLoading(false);
    }    

  };

  return (
    
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="first"
                  required
                  fullWidth
                  id="first"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="last"
                  label="Last Name"
                  name="last"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            { isloading ? <LinearProgress /> : <></>}
            <Typography ariant="subtitle1" gutterBottom color="#F44336">
              {errmsg}
            </Typography>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/auth/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>

          <Grid sx={{pt:2,display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
              <Copyright />
            </Grid>

        </Box>
      </Container>
    
  );
}


export default SignUp