import { useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { AuthContext } from '../AuthContext';

export const useApi = () => {

  const [isRefreshing, setIsRefreshing] = useState(false);
  const { signIn, signOut } = useContext(AuthContext);


  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  // Define a variable to track the number of retries
  let retryCount = 0;

  // Extract the token update logic into a new function
  const updateTokens = async () => {
    const refreshToken = Cookies.get('refresh_token');
    if (refreshToken) {
      const requestBody = {
        jsonrpc: '2.0',
        method: 'auth/refresh',
        id: uuid(),
        debug: true,
        params: { refresh_token: refreshToken },
      };
      try{
        const response = await apiClient.post('/api/jsonrpc', requestBody);
        signIn(response.data.result.access_token, response.data.result.access_expire, response.data.result.refresh_token, response.data.result.refresh_expire,response.data.result.user_info);
      } catch (error) {
        throw error;
      }
    }
  };

  // Intercept 401 errors to refresh the access token
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry && retryCount === 0) {
        originalRequest._retry = true;

        try {
          setIsRefreshing(true);
          await updateTokens(); // Call the new function to update tokens

          originalRequest.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token');
          retryCount += 1; // Increment the retry count
          return apiClient(originalRequest);
        } catch (error) {
          setIsRefreshing(false);
          throw error;
        }
      }
      return Promise.reject(error);
    }
  );

  const request = async (method, params) => {
    try {
        const accessToken = Cookies.get('access_token');
        if (accessToken) {
            apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        } else {
            //Handle when the access token expired but we still have a refresh token
            const refreshToken = Cookies.get('refresh_token');
            if(refreshToken) {
              //Case where user has been deleted.
              try {
                await updateTokens(); // Call the new function to update tokens
                const accessToken = Cookies.get('access_token');
                console.log("SignOut *" + accessToken + "*")
                apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
              } catch (error){
                console.log("SignOut " + error)
                signOut();
              }
            }
        }

        const requestBody = {
            jsonrpc: '2.0',
            method: method,
            id: uuid(),
            debug: true,
            params: params,
        };

        const response = await apiClient.post('/api/jsonrpc', requestBody);
        return response;
    } catch (error) {
      if(error.response.data){
        throw (error.response.data.error.message)
      } else {
        throw error;
      }
    }
  };

  return { request };
};
