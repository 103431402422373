import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import { useApi } from '../../hooks/useApi'
import { Avatar, Box, Button,  Card, CardActions, CardContent, CardHeader, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTheme } from '@mui/material/styles';

import { AuthContext } from '../../AuthContext';


import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import ContentPasteTwoToneIcon from '@mui/icons-material/ContentPasteTwoTone';

function Index() {

    const theme = useTheme();
    const { user } = useContext(AuthContext);
    const [isAdmin, setIsAdmin] = useState(false);

    const jApi = useApi();
    const [isloading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const [teamslayUser,setTeamslayUser] = useState()
    const [stripePrices,setStripePrices] = useState([])


    const fetchData = async () => {    
        try {
            setErrorMessage("")

            if (user) {
                setIsAdmin(user.roles.includes('admin'));
              } else {
                  setIsAdmin(false);
            }


            //Was this load from the returning checkout session?
            const urlParams = new URLSearchParams(window.location.search);
            const session_id = urlParams.get('session_id');
            if(session_id) {
                //First Case:  Update User From Checkout Session
                //This will Add the stripe_customerid to the teamslay_user
                const responseCO = await jApi.request("stripe/checkout/get", { session_id : session_id})
            }
    
            //Get the requesting user based on API Token
            const responsets = await jApi.request("teamslay/user/get", {} )
            const teamslay_user = responsets.data.result
            setTeamslayUser(teamslay_user)

            if(teamslay_user.stripe_customerid === null) {
                const response = await jApi.request("stripe/product/list", {active_only: true});
                var prices = response.data.result.data
                setStripePrices(prices)
            }

        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);

    const getIcon = (name) =>{
        if(name == 'trophy'){
          return icon({ prefix: 'fas', name: 'trophy'});
        }
        if(name == 'dumbbell'){
          return icon({ prefix: 'fas', name: 'dumbbell'});
        }
        if(name == 'check'){
          return icon({ prefix: 'fas', name: 'check'});
        }
        return icon({ prefix: 'fas', name: 'circle-question'});
    }
  


    //ADD NEW SUBSCRIPTION FOR GUEST
    const SubscriptionAddClick = async (price_id) => {
        const foundObject = stripePrices.find(item => item.default_price.id === price_id);
        const return_url =  window.location.href;
        let session_data =  {
          customer_id : teamslayUser.stripe_customerid, 
          success_url : return_url, 
          cancel_url : return_url, 
          price_id : price_id, 
          mode:"subscription", 
          description : foundObject.name 
        }
  
        if(teamslayUser.stripe_customerid === null) {
          session_data.customer_email = teamslayUser.username
        }
  
        const checkout_session = await jApi.request("stripe/checkout/post",session_data)
        window.location.href = checkout_session.data.result.url;
  
  
    }



    if(isloading){
        return(
          <LinearProgress /> 
        )
    }


    return(<>
        { teamslayUser?.stripe_customerid === null ? 

            <Container  maxWidth="md" component="main" sx={{ pt: 4, pb: 4,  alignContent: 'center',  textAlign: 'center' }}>
            <Typography  variant="h4" >
                Discover Your Ideal <br />Fitness Journey
            </Typography>
            <Typography variant='subtitle1' color="text.secondary" sx={{pt:2, pb:4 }}>
                Choose a Plan to Unlock Your Ultimate Fitness Potential
            </Typography>
            <Grid container spacing={5}  >
                {stripePrices?.map((product) => (
                <Grid item key={product.id} xs={12} sm={12} md={6}>
                    <Card elevation={2}>
                    <CardHeader
                        title={
                        <Grid item container direction="column" alignItems="center" justifyContent="center" >
                            <Avatar
                            sx={{
                                backgroundColor: (theme) => theme.palette.primary.main,
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '25px'
                            }}
                            >
                            <FontAwesomeIcon icon={getIcon(product.metadata.faIcon)}  style={{ fontSize: '35px' }} />
                            </Avatar>
                            <Typography variant="h4" color="textPrimary" align="center">
                            {product.name}
                            </Typography>
                        </Grid>
                        }
                        subheader=""
                        titleTypographyProps={{ align: 'left' }}
                        subheaderTypographyProps={{align: 'left', }}
                    />
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignitems: 'baseline',  mb: 3 }} >
                        <Typography variant="h5" color="text.primary">$</Typography>
                        <Typography component="h2" variant="h3" color="text.primary">{product.default_price.unit_amount / 100}</Typography>
                        <Typography variant="h5" color="text.secondary">/month</Typography>
                        </Box>
                        <Stack spacing={1} textAlign="left">
                            <Stack direction="row" spacing={4}>
                            <Typography fontWeight="700">Features</Typography>
                            </Stack>
                            <Stack direction="row" spacing={4} alignItems='center'>
                            <FontAwesomeIcon icon={getIcon('check')} style={{ color: theme.palette.primary.main }} />
                            <Typography variant='body1'>{product.metadata.Feature0}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={4} alignItems='center'>
                            <FontAwesomeIcon icon={getIcon('check')} style={{ color: theme.palette.primary.main }} />
                            <Typography variant='body1'>{product.metadata.Feature1}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={4} alignItems='center'>
                            <FontAwesomeIcon icon={getIcon('check')} style={{ color: theme.palette.primary.main }} />
                            <Typography variant='body1'>{product.metadata.Feature2}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={4} alignItems='center'>
                            <FontAwesomeIcon icon={getIcon('check')} style={{ color: theme.palette.primary.main }} />
                            <Typography variant='body1'>{product.metadata.Feature3}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={4} alignItems='center'>
                            <FontAwesomeIcon icon={getIcon('check')} style={{ color: theme.palette.primary.main }} />
                            <Typography variant='body1'>{product.metadata.Feature4}</Typography>
                            </Stack>                  
                        </Stack>
                    </CardContent>
                    <CardActions style={{ paddingBottom : '20px'}} >
                        <Button fullWidth variant="contained" onClick={() => SubscriptionAddClick(product.default_price.id)} >
                        Choose {product.name}
                        </Button>
                    </CardActions>
                    </Card>   
                </Grid>
                ))}
            </Grid>        
            </Container>        
        
        : 
        <Container  maxWidth="md" component="main" >
        <Grid container spacing={0}  sx={{ pt: 4, alignContent: 'left', textAlign: 'left', }} >
            <Grid item sm={12}>
            <Stack direction="row" spacing={2} alignItems="center">
                <HomeTwoToneIcon sx={{ fontSize: '4rem', verticalAlign: 'middle', color: (theme) => theme.palette.primary.main  }} />

                <Stack spacing={0}>
                    <Typography variant="h6">{teamslayUser?.first} {teamslayUser?.last}</Typography>
                    <Typography variant="body1">{teamslayUser?.username}</Typography>
                </Stack>
            </Stack>

            </Grid>
        </Grid>

        <Grid container spacing={2}  sx={{ pt: 1, pb: 2, alignContent: 'left', textAlign: 'left', }} >
            <Grid item xs={6} sm={4} md={3}>
              <Link to={`/user/subscription`}  style={{ textDecoration: 'none' }}>

                <Card elevation={2} sx={{height: '100%'}}>
                    <CardContent>
                        <Grid  alignItems="center" justifyContent="center"  >
                            <Grid item container direction="column"  alignItems="center" justifyContent="center" >
                                <Avatar
                                    sx={{
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',    
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '10px',
                                    }}
                                >
                                    <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'repeat'})}  style={{ fontSize: '20px' }} />
                                </Avatar>
                                <Typography variant="h5" color="textPrimary" align="center">Subscriptions</Typography>
                            </Grid>
                            <Grid item container alignItems="center" justifyContent="center"  >
                            { isloading ? <LinearProgress /> :
                                <Typography mt={1} variant='body2'  >
                                </Typography>
                                }
                            </Grid>
                        </Grid>
                        

                                
                    </CardContent>

                </Card>
              </Link>                 
            </Grid>                
            <Grid item xs={6} sm={4} md={3}>
              <Link to={`/user/invoice`}  style={{ textDecoration: 'none' }}>

                <Card elevation={2} sx={{height: '100%'}}>
                    <CardContent>
                        <Grid  alignItems="center" justifyContent="center"  >
                            <Grid item container direction="column"  alignItems="center" justifyContent="center" >
                                <Avatar
                                    sx={{
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',    
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '10px',
                                    }}
                                >
                                    <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'file-invoice'})}  style={{ fontSize: '20px' }} />
                                </Avatar>
                                <Typography variant="h5" color="textPrimary" align="center">Invoices</Typography>
                            </Grid>
                            <Grid item container alignItems="center" justifyContent="center"  >
                            { isloading ? <LinearProgress /> :
                                <Typography mt={1} variant='body2'  >
                                </Typography>
                                }
                            </Grid>
                        </Grid>
                        

                                
                    </CardContent>

                </Card>
              </Link>                 
            </Grid>



            <Grid item xs={6} sm={4} md={3}>
            </Grid>
        </Grid>
        
        {isAdmin ? 
            <Grid container spacing={2}  sx={{ pt: 2, alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ContentPasteTwoToneIcon sx={{ fontSize: '2rem', verticalAlign: 'middle', color: (theme) => theme.palette.primary.main  }} />
                        <Typography variant="h6">Administration</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Link to={`/admin/users`}  style={{ textDecoration: 'none' }}>
                        <Card elevation={2} sx={{height: '100%'}}>
                            <CardContent>
                                <Grid  alignItems="center" justifyContent="center"  >
                                    <Grid item container direction="column"  alignItems="center" justifyContent="center" >
                                        <Avatar
                                            sx={{
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',    
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '10px',
                                            }}
                                        >

                                            <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'users'})}  style={{ fontSize: '20px' }} />
                                        </Avatar>
                                        <Typography variant="h5" color="textPrimary" align="center">Users</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>                          
                    </Link>                 
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Link to={`/admin/invoices`}  style={{ textDecoration: 'none' }}>
                        <Card elevation={2} sx={{height: '100%'}}>
                            <CardContent>
                                <Grid  alignItems="center" justifyContent="center"  >
                                    <Grid item container direction="column"  alignItems="center" justifyContent="center" >
                                        <Avatar
                                            sx={{
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',    
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '10px',
                                            }}
                                        >

                                            <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'file-invoice'})}  style={{ fontSize: '20px' }} />
                                        </Avatar>
                                        <Typography variant="h5" color="textPrimary" align="center">Invoices</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>                                      
                    </Link>                 
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Link to={`/admin/coupons`}  style={{ textDecoration: 'none' }}>
                        <Card elevation={2} sx={{height: '100%'}}>
                            <CardContent>
                                <Grid  alignItems="center" justifyContent="center"  >
                                    <Grid item container direction="column"  alignItems="center" justifyContent="center" >
                                        <Avatar
                                            sx={{
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',    
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '10px',
                                            }}
                                        >

                                            <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'ticket'})}  style={{ fontSize: '20px' }} />
                                        </Avatar>
                                        <Typography variant="h5" color="textPrimary" align="center">Coupons</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>                        
                    </Link>                 
                </Grid>


            </Grid>            
        : null}


        <Typography  variant="body1" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>
      </Container>
        }
    </>)
}


export default Index