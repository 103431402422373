import { Avatar, Box, Button,  Card, CardActions, CardContent, CardHeader, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import Copyright from '../../components/Copyright'


function Privacy() {

    return(
        <Container  maxWidth="md" component="main" sx={{ pt: 4, pb: 4,  alignContent: 'left',  textAlign: 'left' }}>
            <Typography  variant="h4" >
                Privacy Policy
            </Typography>

            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Team Slay operates the www.teamslay.fit website, providing online fitness plans. This Privacy Policy outlines how we collect, use, and disclose Personal Information when you use our Service.
            </Typography>

            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            By using our Service, you agree to the collection and use of information as described in this Privacy Policy.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Information Collection and Use</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            To enhance your experience with our Service, we may collect personally identifiable information, including but not limited to your name, phone number, and postal address. This information is used for communication and identification purposes.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Log Data</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            When you visit our Service, we collect Log Data, including your computer’s Internet Protocol ("IP") address, browser version, pages visited, the time and date of your visit, time spent on pages, and other statistics. This data helps us analyze and improve our Service.
            </Typography>


            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Cookies</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            We use cookies, which are small data files sent to your browser from our website. These cookies enhance our Service and offer you a personalized experience. You can choose to accept or refuse cookies; however, rejecting cookies may limit certain functionalities.
            </Typography>            
 
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Service Providers</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Third-party companies and individuals may assist us in providing and analyzing our Service. These entities have access to your Personal Information for task-related purposes only and are prohibited from disclosing or using it for other purposes.
            </Typography>
 
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Security</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            We strive to protect your Personal Information using commercially acceptable methods. However, we cannot guarantee 100% security over the internet. Please be aware that the transmission of information and electronic storage involves inherent risks.
            </Typography>
 
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Links to Other Sites</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Our Service may contain links to third-party websites. We do not control these external sites and recommend reviewing their Privacy Policies. We assume no responsibility for the content, privacy policies, or practices of third-party sites.

            </Typography>
 
            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Children’s Privacy</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            Our Services are not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If we discover such information, we promptly delete it. If you are a parent or guardian and aware of your child providing us with personal information, please contact us.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Changes to This Privacy Policy</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            We may update this Privacy Policy periodically. Please review this page for any changes. We will notify you of significant changes by posting the updated Privacy Policy on this page. The changes are effective immediately upon posting.
            </Typography>

            <Typography variant='h5'  sx={{pt:2, pb:2 }}>Contact Us</Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            If you have questions or suggestions about our Privacy Policy, please contact us.
            </Typography>
            <Typography variant='body1'  sx={{pt:2, pb:2 }}>
            support@teamslay.fit
            </Typography>

            <Grid sx={{pt:2,display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
              <Copyright />
            </Grid>

        </Container>

    )
}


export default Privacy