
import React, { useState, useEffect } from 'react';

import {Avatar,Box,Chip,CircularProgress,Divider,LinearProgress,Grid,Paper,Skeleton,Stack,Typography} from '@mui/material'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@mui/material';
import {Button,ButtonGroup} from '@mui/material'
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faCcVisa, faCcMastercard, faCcAmex,  faCcJcb, faCcDiscover } from "@fortawesome/free-brands-svg-icons"
import { useApi } from '../../../hooks/useApi'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'

import Tooltip from '@mui/material/Tooltip';

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import TextField from '@mui/material/TextField';
function SlidePromotionCode({coupon_id}) {

    const [isloading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [subscriptions, setSubscriptions] = useState(null);
    const [data, setData] = useState(null);
    const jApi = useApi();

    const fetchData = async () => {    
        try {
            setIsLoading(true);
            const response = await jApi.request("stripe/promotion_code/list", {"coupon_id" : coupon_id});
            // console.log("promo codes");
            // console.log(response.data.result.data);
            setData(response.data.result.data);
        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect( () => {
        setIsLoading(true);
        fetchData();
    }, []);



    //#region New Dialog

    const [dialogNew, setDialogNew] = useState({
        open: false,
        loading: false,
        posting: false,
        errorMessage: '',
        code : '',
        code_error: false,
    });

    const dialogNew_onOpen = async () => {
        setDialogNew((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: '',      
          code : '',
          code_error: false,
        }));
    };
  
    const dialogNew_onClose =  () => {
        setDialogNew((prevState) => ({...prevState, open: false,       
          code : '',
          code_error: false,
        }));
    };
  
  
    const dialogNew_onInputChange = (e) => {
        const { name, value } = e.target;
        setDialogNew((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };


    
    const dialogNew_ValidateForm = () => {

        const { code } = dialogNew

        let errors = {
            code_error : false
        }

        if(code === "") { errors.code_error = true }
 
        setDialogNew((prevState) => ({
            ...prevState,
            code_error : errors.code_error,
        }))

        let inError = errors.code_error ;
        return inError;
    };



    const dialogNew_onSubmit = async () => {
        const { code } = dialogNew
        const inError = dialogNew_ValidateForm();
  
        if(!inError) {
            try {
                setDialogNew((prevState) => ({...prevState, posting: true }));
                const response = await jApi.request("stripe/promotion_code/post", {coupon_id : coupon_id , code: code})
                setDialogNew((prevState) => ({...prevState, open: false }));
                await fetchData();
            } catch (error) {
                setDialogNew((prevState) => ({...prevState, errorMessage: error }));
            } finally {
                setDialogNew((prevState) => ({...prevState, posting: false }));
            }
        }
    }
    //#endregion


  
    const handlePauseClick = async (id) => {
        setIsLoading(true);
        try {
            const response = await jApi.request("stripe/promotion_code/patch", { "promotion_code_id" : id, active : false });
        } catch (error) { }
        fetchData();
      };
  
      const handleResumeClick = async (id) => {
          setIsLoading(true);
          try { 
            const response = await jApi.request("stripe/promotion_code/patch", { "promotion_code_id" : id, active : true });
          } catch(error) {}
          fetchData();
      };


    return( <>
                <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'space-between',}} >
                <Stack  direction="row" spacing={2}  alignItems="center">
                    <Avatar
                        sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        width: '22px',
                        height: '22px',
                        }}
                    >
                        <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'tag'})}  style={{ fontSize: '12px' }} />
                    </Avatar>
                    <Typography variant="h6" mb={0} color="textPrimary" align="center">Promotion Codes</Typography>
                </Stack>
                <Button  variant="text" onClick={() => dialogNew_onOpen()}>+ ADD Promotion Code</Button>
            </Stack>            
            <Divider></Divider> 

            <TableContainer  component={Paper}  sx={{ marginTop: '16px' }}>
                <Table  aria-label="simple table"  size="small" >
                    <TableHead>
                        <TableRow>
                        <TableCell>Promotion Code</TableCell>
                        <TableCell>Status</TableCell>
                            <TableCell>Redemptions</TableCell>
                            <TableCell>Expires</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { isloading !== false ? 
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell colSpan={6}  component="th" scope="row">
                                <Skeleton /> 
                            </TableCell>
                        </TableRow>
                    : 
                    <>
                    { data?.map((item) => (
                         <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row" sx={{color: (theme) => theme.palette.primary.main }} >
                                <Typography variant="body2" color="textPrimary" align="left">{item.code}</Typography>
                            </TableCell>
                            <TableCell >
                                <>
                            
                                    { item?.active ? 
                                        <Chip color="success" label="active" size="small"  variant="contained"/> 
                                    :  <Chip color="warning" label="inactive" size="small"  variant="outlined"/> }
                                
                                </>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2" color="textPrimary" align="left">{item.times_redeemed}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2" color="textPrimary" align="left">{item.expires_at}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2" color="textPrimary" align="left">{new Date(item.created* 1000).toLocaleDateString('en-US')}</Typography>
                            </TableCell>
                            <TableCell align="right">
                            { item.id === "" ? <></> : 
                            <>
                        
                                { !item?.active ? 
                                <Tooltip title="Activate Code">
                                <IconButton size="small" aria-label="resume"  onClick={() => handleResumeClick(item.id)} sx={{color: (theme) => theme.palette.primary.main }}>
                                    <PlayArrowIcon  fontSize="inherit"/>
                                </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="De-activate Code">
                                <IconButton size="small" aria-label="pause"  onClick={() => handlePauseClick(item.id)} sx={{color: (theme) => theme.palette.primary.main }}>
                                <PauseIcon fontSize="inherit" />
                                </IconButton>
                                </Tooltip>
                                }
                                </> 
                            } 
                            </TableCell>
                        </TableRow>
                        )

                    )}
                    </>
                }
                </TableBody>
                </Table>
            </TableContainer>

            
            <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>


            <Dialog open={dialogNew.open}
          onClose={dialogNew_onClose} 
          aria-labelledby="alert-dialog-title" 
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
              {"Add New Promotion Code"}
          </DialogTitle>
          <DialogContent>

                <Stack>
                <Typography  variant="body" > Create a new promotion code that customers can use to receive the coupon discount. </Typography>
                <Box component="form" sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                        <TextField
                            size="small"
                            onChange={dialogNew_onInputChange}
                            value={dialogNew.code}
                            error={dialogNew.code_error} // Apply error styling if formErrors.first is true
                            helperText={dialogNew.code_error ? 'Promotion code is required' : ''}
                            autoComplete="code"
                            name="code"
                            required
                            fullWidth
                            id="code"
                            label="Promotion Code"
                            autoFocus
                        />
                        </Grid>
                    </Grid>
                </Box>
                <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogNew.errorMessage}</Typography>
                </Stack>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>                    
          </DialogContent>
          <DialogActions>
              {dialogNew.posting ? 
                  <Button variant="contained"  >
                      <CircularProgress size={14} sx={{ color: 'white' }} /> Add Promotion Code
                  </Button>
              :
                  <Button variant="contained" onClick={dialogNew_onSubmit} autoFocus>
                      Add Promotion Code
                  </Button>
              }
              <Button variant="outlined"  color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogNew_onClose}>Close</Button>
          </DialogActions>
      </Dialog>   

    </> )
}


export default SlidePromotionCode