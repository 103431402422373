import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import HomeIcon from '@mui/icons-material/Home';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Breadcrumbs, Button, Box, Chip, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { useApi } from '../../../hooks/useApi'
import { DataGrid, getGridStringOperators, GridRowId, GridPaginationModel} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox'
import LinearProgress from '@mui/material/LinearProgress';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import TextField from '@mui/material/TextField';

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import MuiLink from '@mui/material/Link'
function Invoices() {

    const jApi = useApi();

    const PAGE_SIZE = 25;
    const [errorMessage, setErrorMessage] = useState();

    const [rows,setRows] = useState({ data :[], has_more_next : false, has_more_prev : false});
    const [isLoading, setIsLoading] = useState(true);

    const defaultPagination = {
        starting_after: null,
        ending_before: null,
        pageSize: PAGE_SIZE,
        page: 0,
    };
    const [paginationModel, setPaginationModel] = useState(defaultPagination);

   
    const [openOnly, setOpenOnly] = useState(false)
    const handleOpenOnly = async (event) => {
      setOpenOnly(event.target.checked);
      setPaginationModel(defaultPagination);
      await fetchData(defaultPagination, event.target.checked);
    }

    const fetchData = async (pm, oo) => {    
        try {
            setErrorMessage("")
            let status = null;
            if(oo) {
              status = 'open'
            }

            setIsLoading(true);
            const responseUsers = await jApi.request("stripe/invoice/list", { starting_after : pm?.starting_after, ending_before: pm?.ending_before, limit : pm?.pageSize , status : status},);

            let has_more_next = false;
            let has_more_prev = false;
            var invoices = []; 
            if(responseUsers.data.result.data) {
   
                invoices = responseUsers.data.result.data
                if((pm?.starting_after === null &&  pm?.ending_before === null)  ) { 
                    has_more_next = responseUsers.data.result.has_more;
                }

                if(pm?.starting_after !== null){
                    has_more_next = responseUsers.data.result.has_more;
                    has_more_prev = true;
                }

                
                if(pm?.ending_before !== null ) { 
                    has_more_next = true;
                    has_more_prev = responseUsers.data.result.has_more
                }

            }

            setRows({ data: invoices, has_more_next : has_more_next, has_more_prev : has_more_prev }); 

        } catch (error) {
            setErrorMessage("Error: " + error)
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        fetchData(paginationModel, openOnly);
    }, []);

      const handlePageNext = async (starting_after) => {
        const newPaginationModel = {
            starting_after: starting_after,
            ending_before: null,
            pageSize: PAGE_SIZE,
          }
        setPaginationModel(newPaginationModel);
        await fetchData(newPaginationModel, openOnly)
      };

      const handlePagePrevious = async (ending_before) => {
        const newPaginationModel = {
            starting_after: null,
            ending_before: ending_before,
            pageSize: PAGE_SIZE,
        }
        setPaginationModel(newPaginationModel);
        await fetchData(newPaginationModel, openOnly)
      };


      function CustomFooterStatusComponent(props) {
        return (
            <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'end',}} >
 
                {rows.has_more_prev ? 
                    <Button  variant="text"  onClick={() => handlePagePrevious(rows.data[0].id) }>previous</Button>
                    :
                    <Button  variant="text" disabled  >previous</Button>
                }
                {rows.has_more_next ? 
                    <Button  variant="text"  onClick={() => handlePageNext(rows.data[rows.data.length-1].id) }  >next</Button>
                    :
                    <Button  variant="text" disabled  >next</Button>
                }
                
            </Stack>
        );


      }

      const InvoiceChip = ({ status, amount, amount_refunded, next_payment_attempt }) => {

        let chip = <Chip  color='default' label={status} size="small" />
        if (status === 'paid') {
          chip = <Chip  color='success' sx={{backgroundColor: (theme) => theme.palette.success.light }} label={status} size="small" />
        } else if (status === 'draft' || status === 'void' || status === 'uncollectible' ) {
          chip = <Chip  color='info' label={status} size="small" />
        }  else  {
          chip = <Chip  color='error' label={status} size="small" />
        }

        if(next_payment_attempt != null) {
          chip = <Chip  color='error' label='retrying' size="small" />
        }

       
        if(amount_refunded === amount){
          chip = <Tooltip title="Fully Refunded"><Chip size="small" icon={<TurnLeftIcon />} color='success' sx={{backgroundColor: (theme) => theme.palette.success.light }} label={status}  /></Tooltip>
        } else if (amount_refunded !== amount && amount_refunded > 0) {
          const tooltipString = "Partial Refund.   " + (amount_refunded /100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
          chip = <Tooltip title={tooltipString}><Chip icon={<TurnLeftIcon />} color='success'  sx={{backgroundColor: (theme) => theme.palette.success.light }} label={status} size="small" /></Tooltip>

        }
      
        return chip;
      };    
    
    // #region Refund Dialog
    const [dialogRefund, setDialogRefund] = useState({
      open: false,
      loading: false,
      posting: false,
      errorMessage: '',
      charge : null,
      name : '',
      number : '',
      refund_amount : 0,
      refund_amount_error: false
    });

    const dialogRefund_onOpen = async (charge, name, number) => {
      setDialogRefund((prevState) => ({ ...prevState, open: true, loading: false, errorMessage: '', charge : charge, refund_amount : (charge.amount - charge.amount_refunded) / 100 , name : name, number : number}));
    };

    const dialogRefund_onClose =  () => {
      setDialogRefund((prevState) => ({...prevState, open: false, charge : null, refund_amount : 0, name : '', number : ''}));
    };

    const dialogRefund_onSubmit = async () => {
      const { posting, charge, refund_amount } = dialogRefund
      if(posting) {
        return
      }
 
      try {
        setDialogRefund((prevState) => ({...prevState, posting: true }));
        const response = await jApi.request("stripe/refund/post", { "charge_id" : charge.id, "amount" : refund_amount * 100 });
        setDialogRefund((prevState) => ({...prevState, open: false }));
        await fetchData(paginationModel, openOnly);
      } catch (error) {
        setDialogRefund((prevState) => ({...prevState, errorMessage: error }));
      } finally {
        setDialogRefund((prevState) => ({...prevState, posting: false }));
      }
    };

    const dialogRefund_onInputChange = (e) => {
      const { name, value } = e.target;
      setDialogRefund((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    // #endregion


    // Function to format date
    const formatFailedToolTip = (failure_message, attempt_count, next_payment_attempt) => {
      try {

        let message = ""
        if(failure_message != null){
          message = failure_message
        }
        if(next_payment_attempt != null){
          message = message + '  Attempted to charge customer ' + attempt_count +  ' times.' + '  Payment will be retried on  ' + new Date(next_payment_attempt * 1000).toLocaleDateString('en-US', {
            month: 'short', // Abbreviated month name (e.g., "Jan")
            day: 'numeric' // Day of the month (e.g., "24")
          }) + '.';
        }
        return message
        // return formattedDate;
      } catch {
       console.log("error")
      }
    }


    return(
        <Container  maxWidth="md" component="main" >
            <Grid container spacing={0}  sx={{ pt: 4, alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                  <Breadcrumbs aria-label="breadcrumb" >
                    <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                    <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Invoices</Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sx={{pt: 1, }}>
                    <Typography  variant="h5"> Invoices</Typography>
                </Grid>
                <Grid item xs={12} sx={{   paddingTop: '0px' }} >
                    <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'space-between',}} >
                    <FormControlLabel control={<Checkbox
                        size="small"
                        checked={openOnly}
                        onChange={handleOpenOnly}
                        inputProps={{ 'aria-label': 'controlled' }}
                    /> } label="Open Only?"  />
                       
                    </Stack>
                </Grid>
                <Grid container spacing={0}  sx={{ pt: 0, alignContent: 'left', textAlign: 'left', }} >
                <DataGrid
                  rows={rows.data}
                  columns={[
                      { field: 'number', headerName: 'Number',  width: 165,  sortable : false, filterable : false,
                      renderCell: (params) => (
                        <MuiLink href={params.row.hosted_invoice_url} target='_blank' style={{ textDecoration: 'none' }}>
                        {params.value}
                      </MuiLink>
                      )
                     },
                      { field: 'amount_due', headerName: 'Due',  width: 165,  sortable : false, filterable : false,

                      renderCell: (params) => (
                        
                        <MuiLink href={params.row.charge?.receipt_url} target='_blank' style={{ textDecoration: 'none' }}>
                           <Tooltip title={formatFailedToolTip(params.row.charge?.failure_message,params.row?.attempt_count, params.row?.next_payment_attempt)}>

                            <Stack  direction="row" spacing={1}>
                                <Typography variant='body2'>
                                {(params.value /100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
                                </Typography>
                              <InvoiceChip status={params.row.status} amount={params.row.charge?.amount} amount_refunded={params.row.charge?.amount_refunded} next_payment_attempt={params.row?.next_payment_attempt}></InvoiceChip>
                            </Stack>
                          </Tooltip>
                        </MuiLink>
                        ),
                      },
                      { field: 'customer_name', headerName: 'Name',  width: 165,  sortable : false, filterable : true, },
                      { field: 'customer_email', headerName: 'Email',  width: 165,  sortable : false, filterable : false, },
                      { field: 'created', headerName: 'Created',  width: 100,  sortable : false, filterable : false,
                      valueFormatter: (params) => {
                          const formattedDate = new Date(params.value* 1000).toLocaleDateString('en-US');
                          return formattedDate;
                        }, 
                      },
                      { field: 'charge', headerName: 'Actions', width:65, sortable: false, filterable : false,
                      renderCell: (params) => (
                        <>
                          { params.row.charge?.refunded ?
                            <IconButton size="small" aria-label="refund" disabled >
                              <TurnLeftIcon  fontSize="inherit"/>
                            </IconButton>
                            :
                            <Tooltip title="Issue Refund">
                            <IconButton size="small" aria-label="refund" onClick={() => dialogRefund_onOpen(params.value, params.row.customer_name, params.row.number)}  sx={{color: (theme) => theme.palette.primary.main }}>
                              <TurnLeftIcon  fontSize="inherit"/>
                            </IconButton>
                            </Tooltip>
                          }

                        </>

                      )
                    }
                      
                  ]}
                  showPagination={false}
                  pageSize={PAGE_SIZE}
                  rowCount={1000000} // Not using this but it is required
                  loading={isLoading}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  hideFooterRowCount={true}
                  slots={{
                      footer: CustomFooterStatusComponent,
                    }}
                />
                </Grid>
            </Grid>
            <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>

            {/* Add Refund Payment Dialog */}
            <Dialog
              open={dialogRefund.open} onClose={dialogRefund_onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"              >
              <DialogTitle id="alert-dialog-title">
                  {"Issue Refund"}
              </DialogTitle>
              <DialogContent>
                { dialogRefund.loading ? 
                    <LinearProgress></LinearProgress>
                  : 
                  <Stack>
                    <Typography variant='body1'>Refunding {dialogRefund.name} ({dialogRefund.number}). </Typography>
                    <Typography variant='body2'>Refunds take 5-10 days to appear on a customer's statement. </Typography>
                  <Box component="form" sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          onChange={dialogRefund_onInputChange}
                          value={dialogRefund.refund_amount}
                          error={dialogRefund.refund_amount_error} // Apply error styling if formErrors.first is true
                          helperText={dialogRefund.refund_amount_error ? 'Invalid Amount' : ''}
                          autoComplete="given-name"
                          name="refund_amount"
                          required
                          fullWidth
                          id="refund_amount"
                          label="Refund Amount"
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogRefund.errorMessage}</Typography>

                </Stack>
                  }                
              </DialogContent>
              <DialogActions>
              {dialogRefund.posting ? 
                  <Button >
                      <CircularProgress size={14}  />Refund
                  </Button>
                :
                  <Button onClick={dialogRefund_onSubmit}>Refund</Button>
              }
              <Button  sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogRefund_onClose}>Close</Button>
              </DialogActions>
            </Dialog>
        
        
        </Container>
  
    )
}

export default Invoices

