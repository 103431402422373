import React,  { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom'; // Import Link from React Router

import Container from "@mui/material/Container"
import { Divider } from '@mui/material';
import Button from '@mui/material/Button'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Skeleton from "@mui/material/Skeleton"
import { Link } from 'react-router-dom'; // Import Link from React Router

import Typography from '@mui/material/Typography';
import { useApi } from '../../../hooks/useApi'
import { useTheme } from '@mui/material/styles';

import {loadStripe} from '@stripe/stripe-js';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'


import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import SlidePromotionCode from './SlidePromotionCode';


function UserDetail() {

    const { couponId } = useParams();
    const [isloading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [coupon, setCoupon] = useState(null);

    const jApi = useApi();

    const fetchData = React.useCallback(async (page) => {
        try {
            setErrorMessage("")
            const responseData = await jApi.request("stripe/coupon/get", {coupon_id : couponId });
            const data = responseData.data.result;
            setCoupon(data);
            console.log(data)
        } catch (error) {
            console.log(error)
            setErrorMessage('Error: ' +  error);
        } finally {
            setIsLoading(false);
        }
    }, [ ]);
      
    useEffect(() => {
        fetchData(); // Fetch the first page initially
    }, []);  

    return( 
        
        <Container  maxWidth="md" component="main" sx={{ pt: 4, pb: 4 }}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link to={`/home`}><HomeTwoToneIcon color="primary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                <Link to={`/admin/coupons`}>Coupons</Link>
                <Typography color="text.primary">{coupon ? coupon.name: <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}</Typography>
            </Breadcrumbs>            
            <Typography  variant="h5" pt={1} pb={2}>
                {coupon ? coupon.name: <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
            </Typography>

            <Divider  ></Divider>
            
            <Grid container spacing={2} >
                <Grid item  md={12}>
                    <Stack   direction="row" spacing={2} flex>
                        <Stack>
                            <Typography variant='subtitle2' sx={{ color: (theme) => theme.palette.grey[500] }} >Terms</Typography>
                            <Typography variant='body2'>
                                {isloading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} /> : <>
                                {  coupon.amount_off ? <>${coupon.amount_off /100} </> : <>{coupon.percent_off}% </>}
                                    off  {coupon.duration === 'forever' || coupon.duration === 'once' ? <> {coupon.duration}</>:
                                    <> for {coupon.duration_in_months} months
                                    </> }

                                </>
                                }
                            </Typography>
                        </Stack>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Stack>
                            <Typography variant='subtitle2' sx={{ color: (theme) => theme.palette.grey[500] }}>Usage</Typography>
                            <Typography variant='body2'>
                            {isloading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} /> : <>
                            { coupon.max_redemptions ? <>{coupon.times_redeemed} out of {coupon.max_redemptions} redemptions</> : <>{coupon.times_redeemed} redemptions</>}
                            </> }
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container spacing={2} pt={2}>
                <Grid item  md={12}>
                    {couponId !== "" ?  <SlidePromotionCode coupon_id={couponId}></SlidePromotionCode> : null}
                </Grid>
                <Grid item sm={12}>
                    <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {errorMessage}</Typography>
                </Grid>
            </Grid>
        </Container>


    )

}

export default UserDetail