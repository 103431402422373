
import React, { useState, useEffect } from 'react';

import {Avatar,Chip,CircularProgress,Divider,LinearProgress,Paper,Skeleton,Stack,Typography} from '@mui/material'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@mui/material';
import {Button,ButtonGroup} from '@mui/material'
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faCcVisa, faCcMastercard, faCcAmex,  faCcJcb, faCcDiscover } from "@fortawesome/free-brands-svg-icons"
import { useApi } from '../../../hooks/useApi'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'

import Tooltip from '@mui/material/Tooltip';

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function SlideSubscription({teamslay_user}) {

    const [isloading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [subscriptions, setSubscriptions] = useState(null);

    const jApi = useApi();
    
    const fetchData = async () => {    

      //Function to get the upcoming invoice
      const fetchSubNextInvoice = async (item) => {

          try {
              const response = await jApi.request("stripe/invoice_upcoming/get", {"customer_id" : teamslay_user.stripe_customerid, "subscription_id" : item.id})
              const r = response.data.result;
              item.amount_due = r.amount_due / 100,
              item.date_due =  (new Date(r.next_payment_attempt * 1000)).toLocaleString('default', { month: 'short', day: 'numeric' })

              const responseS = await jApi.request("stripe/invoice/list", {"customer_id" : teamslay_user.stripe_customerid,  "subscription_id" : item.id })
              item.invoice_count = responseS.data.result.data.length

            } catch (error) {
            console.error(`Error fetching data for item ${item.id}: ${error}`);
          }
          return item;
      };


      // Function to extract brand and last4 from default_payment_method
      const extractPaymentInfo = (subscription) => {
        if (subscription.default_payment_method && subscription.default_payment_method.card) {
          return {
            ...subscription,
            brand: subscription.default_payment_method.card.brand,
            last4: subscription.default_payment_method.card.last4,
            exp_month: subscription.default_payment_method.card.exp_month,
            exp_year: subscription.default_payment_method.card.exp_year
          };
        } else {
          return {
            ...subscription,
            brand : '',
            last4 : '',
            exp_month : '',
            exp_year: ''
          }
        }
      };

      try {

        setIsLoading(true);
        setErrorMessage();

        if(teamslay_user.stripe_customerid === null) {
          return null;
        }

        const response = await jApi.request("stripe/subscription/list", {"customer_id" : teamslay_user.stripe_customerid})
        var localSubscriptions = [];
        if(response.data.result.data) {
          localSubscriptions = response.data.result.data;
        }

        //Maps Upcoming Invoices and total invoices for subscription (Used to see if user can cancel)
        const fetchUpcomingInvoicePromises = localSubscriptions.map(fetchSubNextInvoice);
        const updatedSubscriptions = await Promise.all(fetchUpcomingInvoicePromises);

        //Maps over the array and add Payment method
        const updatedSubscriptions1 = updatedSubscriptions.map(extractPaymentInfo);
        setSubscriptions(updatedSubscriptions1)
      }catch (error) {
        setErrorMessage("Error: " + error)
      } finally {
        setIsLoading(false);
      }
    };

    useEffect( () => {
        setIsLoading(true);
        fetchData();
    }, []);

    const SubscriptionChip = ({ status }) => {
      let chipColor = 'default'; 
      if (status === 'active') {
        chipColor = 'success';
      } else if (status === 'past_due') {
        chipColor = 'error';
      } 
    
      return <Chip color={chipColor} label={status} size="small" />;
    };    

    const getCardIcon = (name) =>{
      name = name.toLowerCase();
      if(name == 'visa'){
        return faCcVisa;
      }
      if(name == 'mastercard'){
        return faCcMastercard;
      }
      if(name == 'jcb'){
        return faCcJcb;
      }
      if(name == 'discover'){
        return faCcDiscover;
      }
      if(name == 'amex'){
        return faCcAmex;
      }
      return icon({ prefix: 'fab', name: 'credit-card'});
    }
 
    // #region Cancel Dialog
  
    const [dialogCancel, setDialogCancel] = useState({
      open: false,
      loading: false,
      posting: false,
      errorMessage: '',
      subscription_id : ''
    });

    const dialogCancel_onOpen = async (subscription_id) => {
      setDialogCancel((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: '', subscription_id : subscription_id}));
    };

    const dialogCancel_onClose =  () => {
      setDialogCancel((prevState) => ({...prevState, open: false, subscription_id : ''}));
    };

    const dialogCancel_onSubmit = async () => {
      const { posting, subscription_id } = dialogCancel
      if(posting) {
        return
      }

      try {
        setDialogCancel((prevState) => ({...prevState, posting: true }));
        const response = await jApi.request("stripe/subscription/cancel", { "subscription_id" : subscription_id });
        setDialogCancel((prevState) => ({...prevState, open: false }));
        await fetchData();
      } catch (error) {
        setDialogCancel((prevState) => ({...prevState, errorMessage: error }));
      } finally {
        setDialogCancel((prevState) => ({...prevState, posting: false }));
      }
    };

    // #endregion

    // #region Add Dialog
  
    const [dialogAdd, setDialogAdd] = useState({
      open: false,
      loading: false,
      posting: false,
      errorMessage: '',
      list : [],
      price_id : ''
    });

    const dialogAdd_onOpen = async () => {
      setDialogAdd((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: ''}));
      try {
        const response = await jApi.request("stripe/product/list", {active_only: true});
        var prices = response.data.result.data.map(({ default_price: { id }, name, default_price: { unit_amount } }) => ({ id, name, unit_amount  }));
        let price_id_default = ''
        if (prices.length > 0) {
          price_id_default = prices[0].id;
        }
        setDialogAdd((prevState) => ({...prevState, list: prices, price_id : price_id_default }));
      } catch (error) {
        setDialogAdd((prevState) => ({...prevState, errorMessage: error, list: [] }));
      } finally {
        setDialogAdd((prevState) => ({...prevState, loading: false }));
      }
    };

    const dialogAdd_onClose =  () => {
      setDialogAdd((prevState) => ({...prevState, open: false, price_id : ''}));
    };

    const dialogAdd_onSubmit = async () => {

      const { posting, list, price_id } = dialogAdd

      if(posting) {
        return
      }

      try {
        setDialogAdd((prevState) => ({...prevState, posting: true }));

        const foundObject = list.find(item => item.id === price_id);
        const return_url =  window.location.href;
        const checkout_session = await jApi.request("stripe/checkout/post", {
          customer_id : teamslay_user.stripe_customerid, 
          success_url : return_url, 
          cancel_url : return_url, 
          price_id : price_id, 
          mode:"subscription", 
          description : foundObject.name 
        })
        window.location.href = checkout_session.data.result.url;

      } catch (error) {
        setDialogAdd((prevState) => ({...prevState, errorMessage: error }));
      } 
    };

    const dialogAdd_onRadioChange = (event) => {
      const price_id_event = event.target.value;
      setDialogAdd((prevState) => ({...prevState, price_id: price_id_event}));
    };


    // #endregion

    // #region Edit Dialog
    
    const [dialogEdit, setDialogEdit] = useState({
      open: false,
      loading: false,
      posting: false,
      errorMessage: '',
      subscription_id : '',
      payment_id : '',
      list : []
    });

    const dialogEdit_onOpen = async (subscription_id, payment_id, coupon_id) => {
      setDialogEdit((prevState) => ({ ...prevState, open: true, loading: true, errorMessage: '', subscription_id : subscription_id, coupon_id : coupon_id}));
      try {
        const responsePM = await jApi.request("stripe/payment_method/list", {customer_id : teamslay_user.stripe_customerid })
        var pms = responsePM.data.result.data.map(( { id , card: { brand }, card: { exp_month }, card: { exp_year }, card: { last4 } }) => ({ id, brand, exp_month, exp_year, last4  }));

        if(payment_id === null) {
          payment_id = ''
          if (pms.length > 0) {
            payment_id = pms[0].id;
          }
        }

        setDialogEdit((prevState) => ({...prevState, list: pms,  payment_id : payment_id }));
      } catch (error) {
        setDialogEdit((prevState) => ({...prevState, errorMessage: error, list: [], clist : [] }));
      } finally {
        setDialogEdit((prevState) => ({...prevState, loading: false }));
      }
    };

    const dialogEdit_onClose =  () => {
      setDialogEdit((prevState) => ({...prevState, open: false, subscription_id: '', payment_id : '' }));
    };

    const dialogEdit_onSubmit = async () => {
      const { subscription_id, payment_id } = dialogEdit
      try {
        setDialogEdit((prevState) => ({...prevState, posting: true }));
        const responsePM = await jApi.request("stripe/subscription/patch", {subscription_id : subscription_id , default_payment_method : payment_id, customer_id : teamslay_user.stripe_customerid })
        setDialogEdit((prevState) => ({...prevState, open: false }));
        await fetchData();
      } catch (error) {
        setDialogEdit((prevState) => ({...prevState, errorMessage: error }));
      } finally {
        setDialogEdit((prevState) => ({...prevState, posting: false }));
      }
    };

    const dialogEdit_onPaymentChange = (event) => {
      const payment_id = event.target.value;
      setDialogEdit((prevState) => ({...prevState, payment_id: payment_id}));
    };

    // #endregion

    return(
        <>
            <Stack  direction="row" spacing={2}  alignItems="center" sx={{ justifyContent: 'space-between',}} >
                <Stack  direction="row" spacing={2}  alignItems="center">
                    <Avatar
                        sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        width: '22px',
                        height: '22px',
                        }}
                    >
                        <FontAwesomeIcon icon={icon({ prefix: 'fas', name: 'refresh'})}  style={{ fontSize: '12px' }} />
                    </Avatar>
                    <Typography variant="h6" mb={0} color="textPrimary" align="center">Subscriptions</Typography>
                </Stack>
                <Button  variant="text" onClick={() => dialogAdd_onOpen()}>+ ADD</Button>
            </Stack>            
            <Divider></Divider>   
            <TableContainer  component={Paper}  sx={{ marginTop: '16px' }}>
                <Table  aria-label="simple table"  size="small" >
                    <TableHead>
                    <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Upcoming</TableCell>
                        <TableCell>Payment Method</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    { isloading !== false ? 
                        <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell colSpan={5}  component="th" scope="row">
                            <Skeleton /> 
                            </TableCell>
                        </TableRow>
                    : 
                    <>
                        {subscriptions?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                            {row.description}
                            </TableCell>
                            <TableCell >
                            <>
                           
                            { row?.pause_collection && row?.pause_collection?.behavior === "void" ? 
                                <Chip color="warning" label="Payment collection paused" size="small"  variant="outlined"/>
                            :   <SubscriptionChip status={row.status}></SubscriptionChip>  }
                            
                            </>
                            </TableCell>
                            <TableCell>
                            ${row?.amount_due} due on {row?.date_due}
                            </TableCell>
                            <TableCell >
                              <>
                               { row?.last4  === "" ? 
                                  <>
                                 
                                    <Typography variant='body' sx={{color: (theme) => theme.palette.error.main }}>
                                      <FontAwesomeIcon  style={{ fontSize: '15px'}}   icon={getCardIcon(row?.brand)} />
                                    </Typography>&nbsp;No payment method
                                  </>
                               : 
                                  <>
                                  <Typography variant='body' sx={{color: (theme) => theme.palette.primary.main }}>
                                      <FontAwesomeIcon  style={{ fontSize: '15px'}}   icon={getCardIcon(row?.brand)} />
                                    </Typography>&nbsp;.... {row?.last4} (Exp: {row?.exp_month}/{row?.exp_year})
                                  </>
                               }
                              </>
                              
                            </TableCell>
                            <TableCell align="right">
                            <ButtonGroup size="small" aria-label="outlined button group">
                                <Tooltip title="Change payment method">
                                  <IconButton size="small" aria-label="edit"  onClick={() => dialogEdit_onOpen(row.id, row?.default_payment_method?.id, row?.discount?.coupon?.id)} sx={{color: (theme) => theme.palette.primary.main }}>
                                    <EditIcon fontSize="inherit"/>
                                  </IconButton>
                                </Tooltip>
                                { row.invoice_count >= 3 ?
                                  <Tooltip title="Cancel Subscription">
                                    <IconButton size="small" aria-label="delete"  onClick={() => dialogCancel_onOpen(row.id)} sx={{color: (theme) => theme.palette.primary.main }}>
                                      <DeleteIcon  fontSize="inherit"/>
                                    </IconButton>
                                  </Tooltip>
                                    :
                                    <Tooltip title="Subscription may be canceled after 3 months">
                                    <IconButton size="small" aria-label="delete"  >
                                      <DeleteIcon  fontSize="inherit"/>
                                    </IconButton>
                                  </Tooltip>
                                }
                            </ButtonGroup>
                            </TableCell>
                        </TableRow>
                        ))}
                    </>
                    }
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography  variant="body1" sx={{ color: (theme) => theme.palette.error.main, pt: 1 }} > {errorMessage}</Typography>
 
             {/* Cancel Subscription Dialog */}
            <Dialog
              open={dialogCancel.open}
              onClose={dialogCancel_onClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title">
                  {"Are you sure?"}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Cancelled subscriptions cannot be re-activated.
                  </DialogContentText>
                  <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogCancel.errorMessage}</Typography>
              </DialogContent>
              <DialogActions>
                  {dialogCancel.posting ? 
                      <Button  variant="contained" color='error'>
                          <CircularProgress size={14}  sx={{ color: 'white' }}  />Cancel Subscription
                      </Button>
                  :
                      <Button  variant="contained" onClick={dialogCancel_onSubmit} autoFocus color='error'>
                        Cancel Subscription
                      </Button>
                  }
                  <Button variant="outlined"  color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogCancel_onClose}>Close</Button>
                </DialogActions>
            </Dialog>     

            {/* Add New Subscription Dialog */}
            <Dialog
              open={dialogAdd.open}
              onClose={dialogAdd_onClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title">
                  {"Add New Subscription"}
              </DialogTitle>
              <DialogContent>
                { dialogAdd.loading ? 
                  <LinearProgress></LinearProgress>
                : <FormControl>
                    <FormLabel id="subscription-label">Select Subscription</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label"
                      value={dialogAdd.price_id}
                      name="radio-buttons-group"
                      onChange={dialogAdd_onRadioChange}
                    >

                    {dialogAdd.list.map((obj) => (
                      <FormControlLabel
                        key={obj.id}
                        value={obj.id}
                        control={<Radio />}
                        label={`${obj.name} - $${(obj.unit_amount / 100).toFixed(2)}`} // Divide by 100 and format as needed
                      />
                    ))}

          
                    </RadioGroup>
                  </FormControl>
                }
                <Typography  variant="body1" sx={{ color: (theme) => theme.palette.error.main }} > {dialogAdd.errorMessage}</Typography>
              </DialogContent>
              <DialogActions>
              {dialogAdd.posting ? 
                  <Button  variant="contained">
                      <CircularProgress size={14} sx={{ color: 'white' }}  />Add Subscription
                  </Button>
                :
                  <Button  variant="contained" onClick={dialogAdd_onSubmit}>Add Subscription</Button>
              }
              <Button variant="outlined"  color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogAdd_onClose}>Close</Button>
              </DialogActions>
            </Dialog>

            {/* Edit Change Payment/Coupon Dialog */}
            <Dialog
              open={dialogEdit.open} onClose={dialogEdit_onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"              >
              <DialogTitle id="alert-dialog-title">
                  {"Edit Subscription"}
              </DialogTitle>
              <DialogContent>
                { dialogEdit.loading ? 
                    <LinearProgress></LinearProgress>
                  : <Stack>
                    <FormControl>
                      <FormLabel id="subscription-label">Select Payment Method</FormLabel>

                      <Select
                      size="small"
                      labelId="duration-label"
                      id="duration"
                      value={dialogEdit.payment_id}
                      onChange={dialogEdit_onPaymentChange}
                  >
                    {dialogEdit.list.map((obj) => (
                      <MenuItem key={obj.id} value={obj.id}>
                        <Stack direction="row" spacing={2}>
                          <Typography  sx={{display: 'inline', color: (theme) => theme.palette.primary.main, }} >
                            <FontAwesomeIcon  sx={{  marginRight: '1px' }}   icon={getCardIcon(obj.brand)} />
                          </Typography>
                          <Typography  >
                            .... {obj.last4} (Exp: {obj.exp_month}/{obj.exp_year})
                          </Typography>
                        </Stack>
                      </MenuItem>  
                    ))}

                  </Select>
                  </FormControl>
                  </Stack>
                  }                
                <Typography  variant="body" sx={{ color: (theme) => theme.palette.error.main }} > {dialogEdit.errorMessage}</Typography>
              </DialogContent>
              <DialogActions>
              {dialogEdit.posting ? 
                  <Button  variant="contained">
                      <CircularProgress size={14}  sx={{ color: 'white' }} />Update Subscription
                  </Button>
                :
                  <Button  variant="contained" onClick={dialogEdit_onSubmit}>Update Subscription</Button>
              }
              <Button variant="outlined"  color="inherit" sx={{ color: (theme) => theme.palette.grey[700] }} onClick={dialogEdit_onClose}>Close</Button>
              </DialogActions>
            </Dialog>
        </>
    )

}

export default SlideSubscription