import * as React from 'react';
import { useState, useRef, useContext } from "react"
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Copyright from '../../components/Copyright';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid } from '@mui/material';
//import { useSignIn } from 'react-auth-kit'
import { useApi } from '../../hooks/useApi'
import { useNavigate } from "react-router-dom"
import { AuthContext } from '../../AuthContext';

const steps = [
  'Identify',
  'Verify',
  'Reset',
];

function ForgotPassword() {

    const [activeStep, setActiveStep] = React.useState(0);
    const [username, setUserName] = React.useState("");
    const [nonce, setNonce] = React.useState("");
    const [errmsg, setErrMsg] = useState();
    const [isloading, setIsLoading] = useState(false);

    const formRef = useRef(null);
    const { signIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const jApi = useApi();

    const handleEmail = async () => {
      if(isloading) {
        return;
      }
      setIsLoading(true);
      setErrMsg("")
      try {
        const formData = new FormData(formRef.current);
        setUserName(formData.get('email'));
        const nonceCreateResponse = await jApi.request("auth/nonce_create", { "username" : formData.get('email') })
        if(nonceCreateResponse.data.error){
          setErrMsg(nonceCreateResponse.data.error["message"]);
          return;
        }
        const nonceSendResponse = await jApi.request("auth/nonce_email", { "username" : formData.get('email') })
        if(nonceSendResponse.data.error){
          setErrMsg(nonceSendResponse.data.error["message"]);
          return;
        }
        setActiveStep(1);
      }
      catch(err) {
        setErrMsg(err);
      }
      finally {
        setIsLoading(false);
      }
    }

    const handleNonce = async () => {
      if(isloading) {
        return;
      }
      setIsLoading(true);
      setErrMsg("")
      try {
        setErrMsg();
        const formData = new FormData(formRef.current);
        setNonce(formData.get('nonce'))
        const nonceCheckResponse = await jApi.request("auth/nonce_validate", { "username" : username, "nonce" : formData.get('nonce') })
        if(nonceCheckResponse.data.result['valid']){
          setActiveStep(2);
        }
        else {
          setErrMsg("Invalid Code.")
        }
      }
      catch(err) {
        setErrMsg(err);
      }
      finally {
        setIsLoading(false);
      }        
    }

    const handlePassword = async () => {
      if(isloading) {
        return;
      }
      setIsLoading(true);
      setErrMsg("")

      try {

        const formData = new FormData(formRef.current);
        if(formData.get('password') != formData.get('confirm')){
          setErrMsg("Passwords don't match.");
          return;
        }

        const passwordResponse = await jApi.request("auth/password_reset", { "username" : username, "nonce" : nonce, "password" : formData.get('password') })
        if(passwordResponse.data.error){
          setErrMsg(passwordResponse.data.error["message"]);
          return;
        }

        const response = await jApi.request("auth/accesstoken", { "username" : username, "password" :  formData.get('password') } );
        if(response.data.result) {
          signIn(response.data.result.access_token, response.data.result.access_expire, response.data.result.refresh_token, response.data.result.refresh_expire,response.data.result.user_info);
          navigate("/home")
        }
        else{
          setErrMsg(response.data.error["message"])
        }

      }
      catch(err) {
        console.log(err)
        setErrMsg(err);
      }
      finally {
        setIsLoading(false);
      }
    }

    const handleBack = () => {
      setErrMsg()
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
 
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <Box component="form" sx={{ width: '100%', marginTop: 3 }} ref={formRef}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <Box sx={{ pt: 2 }}>
              {activeStep === 0 &&  
                  <>
                      <Typography sx={{ mt: 2, mb: 1 }}>Forgotten your passsword? </Typography> 
                      <Typography sx={{ mt: 2, mb: 1 }}>Enter your Email address below. We'll send you a verification code.</Typography>
                      <Box  noValidate sx={{ mt: 1 }}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                        />
                      </Box>
                      { isloading ? <LinearProgress /> : <></>}
                      <Typography ariant="subtitle1" gutterBottom color="#F44336">
                        {errmsg}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" disabled sx={{ mr: 1 }}>Back</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button variant="contained" type="button" onClick={handleEmail} >Next</Button>
                      </Box>
                  </> 
              }
              {activeStep === 1 && 
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>Verify your identity.</Typography>
                    <Typography sx={{ mt: 2, mb: 1 }}>We just sent you an email. Please enter the code to reset your password.</Typography>
                    <Box  noValidate sx={{ mt: 1 }}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="nonce"
                          label="User Validation Code"
                          name="nonce"
                          autoComplete="nonce"
                          autoFocus
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            maxLength: 6,
                          }}
                        />
                      </Box>
                      { isloading ? <LinearProgress /> : <></>}
                      <Typography ariant="subtitle1" gutterBottom color="#F44336">
                        {errmsg}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }} variant="contained">Back</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button variant="contained" type="button" onClick={handleNonce} >Next</Button>
                      </Box>                      
                  </>
              }
              {activeStep === 2 && 
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>Reset Password.</Typography>
                  <Typography sx={{ mt: 2, mb: 1 }}>Please create and confirm your new password.</Typography>
                  <Box  noValidate sx={{ mt: 1 }}>
                        <TextField
                          type="password"
                          margin="normal"
                          required
                          fullWidth
                          id="password"
                          label="Password"
                          name="password"
                          autoComplete="password"
                          autoFocus
                        />
                  </Box>
                  <Box  noValidate sx={{ mt: 1 }}>
                        <TextField
                          type="password"
                          margin="normal"
                          required
                          fullWidth
                          id="confirm"
                          label="Confirm"
                          name="confirm"
                          autoComplete="confirm"
                        />
                  </Box>
                  { isloading ? <LinearProgress /> : <></>}
                  <Typography ariant="subtitle1" gutterBottom color="#F44336">
                        {errmsg}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" disabled sx={{ mr: 1 }}>Back</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button variant="contained" type="button" onClick={handlePassword} >Finish</Button>
                      </Box>                  
                </>
              }
            </Box>
            <Grid sx={{pt:2,display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
              <Copyright />
            </Grid>

          </Box>
        </Box>

      </Container>

     
    );
  }


  export default ForgotPassword