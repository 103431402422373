import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import '@fontsource-variable/public-sans';
import { AuthProvider } from './AuthContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'light', 
  },
  typography: {
    fontFamily : 'Public Sans Variable',
    h4: {
      fontWeight: 700,
    },
  },
  // palette: {
  //   primary: {
  //     main: red[500],
  //   },
  // },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
        <App></App>
        </CssBaseline>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
