import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/Copyright';
import LinearProgress from '@mui/material/LinearProgress';
import LogInIcon from '@mui/icons-material/Login';

import { useNavigate } from "react-router-dom"
import { useState, useContext } from "react"
import { useApi } from '../../hooks/useApi'
import { AuthContext } from '../../AuthContext';

function SignIn () {

    const [errmsg, setErrMsg] = useState();
    const [isloading, setIsLoading] = useState(false);

    const { signIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const jApi = useApi();

    const handleSubmit = async (event) => {

        event.preventDefault();

        if(isloading) {
          return;
        }
        setIsLoading(true);
        setErrMsg("")


        const formData = new FormData(event.target);
        const username = formData.get('email');
        const password = formData.get('password');
        
        try {
          const response = await jApi.request("auth/accesstoken", { "username" : username, "password" : password } );
          if(response.data.result) {
            signIn(response.data.result.access_token, response.data.result.access_expire, response.data.result.refresh_token, response.data.result.refresh_expire,response.data.result.user_info);
            localStorage.clear();
            navigate("/home")
          }
          else{
            setErrMsg(response.data.error["message"])
          }
        }
        catch(err) {
          setErrMsg(err);
        }
        finally {
          setIsLoading(false);
        }

      }

    return (
        <Container component="main" maxWidth="xs">


        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LogInIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <>Sign In</> 
              
            </Button>
            { isloading ? <LinearProgress /> : <></>}
            <Typography ariant="subtitle1" gutterBottom color="#F44336">
              {errmsg}
            </Typography>
            <Grid container>
              <Grid item xs>
                <Link href="/auth/forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/auth/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Grid sx={{pt:2,display: 'flex',flexDirection: 'column', alignItems: 'center'}}>
              <Copyright />
            </Grid>
          </Box>
        </Box>
        
      </Container>
    )
}



export default SignIn